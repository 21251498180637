<div class="common-dropdown">
  <div *ngIf="title" [ngClass]="hasAnyError ? 'error-title' : 'title'">{{ title }}</div>
  <mat-form-field
    [ngClass]="hasAnyError ? 'mat-error-spacer' : ''"
    [hideRequiredMarker]="true"
    id="common-dropdown"
    appearance="fill"
  >
    <mat-select [formControl]="control" [placeholder]="defaultLabel" (selectionChange)="handleChange($event)">
      <mat-option disabled class="default-label" value="">{{defaultLabel}}</mat-option>
      <mat-option [disabled]="handleDisableItem(item)" *ngFor="let item of dropdownMenu" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
    <mat-hint *ngIf="hint" class="hint">
      <cc-icon width="16px" height="16px" [iconUrl]="'/assets/images/icons/hint-icon.png'"></cc-icon>
      <span>{{ hint }}</span>
    </mat-hint>
    <mat-error class="errorMessage" *ngIf="shouldShowErrors()">
      {{ errorMsg }}
    </mat-error>
    <mat-error *ngIf="errorMessage">
      <div class="errorMessage">
        {{ errorMessage }}
      </div>
    </mat-error>
    <mat-error *ngIf="!errorMessage && shouldShowErrors()">
      <div class="errorMessage" *ngFor="let error of listOfErrors()">
        {{ error }}
      </div>
    </mat-error>
  </mat-form-field>
</div>