import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ERROR, REDIRECT_URL } from 'src/app/common/constant';

@Injectable({ providedIn: 'root' })
export class ErrorMessagesService {
  private errorMessages = {
    required: (_: any, component: any) =>
      component.title === 'Authorized Redirect URL' ? 'URL is a mandatory field' : `${component.title} is required`,
    name: (_: any, component: any) => (component.title ? `Invalid ${component.title}` : 'Invalid name'),
    email: (_: any) => 'Invalid email address',
    matching: () => 'Not matching',
    maxlength: (params: any, component: any) =>
      component?.title === (`${REDIRECT_URL.DEFAULT_TITLE}` || `${REDIRECT_URL.ALL_TYPE_OAUTH_TITLE}`)
        ? `${ERROR.LENGTH_EXCEEDED} ${params.requiredLength} characters`
        : `Enter maximum ${params.requiredLength} digits`,
    alias: (params: any) => "Alias ID must contain only 'A-Z', 'a-z', '0-9', '-' ",
    memberValidator: (params: any) => params,
    emailSamsungValidator: (params: any) => params,
    differentEmail: (params: any) => params,
    differentCountry: (params: any) => params,
    googleKey: () => 'This is google public key',
    url: (_: any, component: any) => `Invalid ${component.title}`,
    hashcode: (params: any) => {
      if (params.type === 'long' && params.clientType === 'any') {
        return 'Long Key including numbers and english letter';
      } else if (params.clientType === 'Android') {
        return 'Short Key including (-) and (digit)';
      } else if (params.clientType === 'Windows') {
        return 'App Hash Code including (-), (/), (=) and (alphanumeric)';
      } else {
        return 'Tizen Short Key including uppercase letter and digit';
      }
    },
    protocolRequired: () => 'URI must begin with http:// or https://',
    protocolNotRequired: () => 'Service domain must not begin with http:// or https://',
    domain: () => 'URI must contain domain',
    publicDomain: () => 'Public domain (.com, .org) must be included',
    privateDomain: () => 'Private domain must be included',
    duplicated: () => 'This URL is duplicated',
    invalid: () => 'Invalid URL',
    invalidCharacters: () => 'The URL must not contain any "#" or "%23"',
    invalidSlash: () => 'Service domain must not contain /',
    nativeAppAllowed: () => 'URL must contain //',
    location: () => 'Invalid latitude and longitude',
    lowercase: () => 'Invalid package name',
    emptyFile: (_: any, component: any) => `${component.title} attachment file has no content`,
    consent: () => 'Please read and check the above information.',
  };

  shouldShowErrors(control: FormControl, isSubmitted: boolean): boolean {
    return control && control.invalid && (control.dirty || control.touched || isSubmitted);
  }

  listOfErrors(control: FormControl, component = null): string[] {
    if (!control.errors) {
      return [];
    }
    return Object.keys(control.errors).map((field) => this.getMessage(field, control.errors[field], component));
  }

  checkDuplicity(formArrayControls: FormControl[]): void {
    this.removeAllDuplicityErrors(formArrayControls);
    this.setDuplicityErrors(formArrayControls);
  }

  private removeAllDuplicityErrors(formArrayControls: FormControl[]): void {
    formArrayControls.forEach((_, initialIndex) => {
      const controlErrors = formArrayControls[initialIndex].errors;
      if (controlErrors && Object.keys(controlErrors).some((error) => error === 'duplicated')) {
        formArrayControls[initialIndex].setErrors(null);
      }
    });
  }

  private setDuplicityErrors(formArrayControls: FormControl[]): void {
    formArrayControls.forEach((initialControl, initialIndex) => {
      formArrayControls.forEach((otherControl, otherIndex) => {
        if (initialIndex === otherIndex) return;
        if (initialControl.value === otherControl.value) {
          if (!initialControl.errors) {
            formArrayControls[initialIndex].setErrors({
              duplicated: true,
            });
          }
        }
      });
    });
  }

  private getMessage(type: string, params: any, component: any) {
    return this.errorMessages[type](params, component);
  }
}
