import { ValidatorFn, AbstractControl } from '@angular/forms';

export function PackageNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = /^(@|[a-zA-Z])[a-zA-Z0-9-_.]*$/;
    if (!!control.value) {
      if (!regex.test(control.value)) {
        return { lowercase: true };
      }
    }
    return null;
  };
}
