<div class="cc-input-search">
  <cc-input-text class="search-text" [placeholder]="'Search FAQ'"
    [(value)]="value" (enter)="handleSearch()">
  </cc-input-text>
  <sdc-design-button
  type="primary"
  title="Search"
  width="112px"
  height="30px"
  class="btn-search"
  (click)="handleSearch()"
></sdc-design-button>
</div>
