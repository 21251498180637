import { Component, TemplateRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/service/api';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.scss'],
})
export class UserComponent {
  public profileLink = environment.profileLink;
  public userInfo$ = this.authService.userInfo$;

  userModalRef: MatDialogRef<any>;

  @ViewChild('userModal', { static: true })
  userModal: TemplateRef<any>;

  constructor(private authService: AuthService, private dialog: MatDialog) {}

  handleLogin() {
    this.authService.login();
  }

  handleLogout() {
    this.authService.logout();
  }

  handleUserModal() {
    this.userModalRef = this.dialog.open(this.userModal, {
      panelClass: 'user-modal',
      width: '400px',
      id: 'userModal',
      autoFocus: false,
      restoreFocus: false,
      position: {
        top: '60px',
        right: '60px',
      },
    });
  }

  closeModal() {
    if (this.userModalRef) this.userModalRef.close();
  }
}
