export const CLIENT_TYPE = {
  WEB: 'Web',
  TIZEN: 'Tizen',
  IOS: 'IOS',
  WINDOWS: 'Windows8',
  ANDROID: 'Android',
  NSANDROID: 'NSAndroid',
  ETC: 'Etc',
  OTHER: 'Other',
};

export const REDIRECT_URL = {
  MAXLENGTH: 2048,
  LIMIT: 100,
  ALL_TYPE_OAUTH_TITLE: 'Authentication Redirection',
  DEFAULT_TITLE: 'Authorized Redirect URL',
  ERROR_CODE: 'CLT016',
  ERROR_MSG: 'Sum total of all unique domain lengths of all redirect url exceeds limit 2048',
};

export const ERROR = {
  LIMIT_DEFAULT: 'Maximum Limit Reached',
  LIMIT_EXCEEDED: "Already exceeding allowed limit of 100. Deleted IDs can't be re-added until limit is exceeding",
  LIMIT_REACHED: 'Maximum 100 Redirect Urls allowed',
  LENGTH_EXCEEDED: 'Maximum allowed length is ',
};

export const PACKAGE_TYPE = {
  ANDROID: 'Android',
  TIZEN: 'Tizen',
};

export const PACKAGE_ITEM = {
  package_info: 'Package Name',
  package_name: 'Package Name',
  short_signature: 'Signature Code (Short)',
  long_signature: 'Signature Code (Long)',
  app_hash_code: 'App Hash Code',
  package_type: 'Package Type',
};

export const WEBHOOK_URL = {
  user_withdrawal_notice_url: 'User Delete',
  user_modify_login_id_notice_url: 'User Update Login-id',
  user_modify_notice_url: 'User Update Profile',
  user_valid_email_notice_url: 'User Email Validation',
  user_create_notice_url: 'User Create',
  user_name_check_notice_url: 'User Update Namecheck',
  user_tnc_notice_url: 'User Update Tnc',
  user_expire_token_notice_url: 'User Token Expire',
  user_unused_notice_url: 'User Unused',
  user_unlock_notice_url: 'User Unlock',
  user_notify_newsletter_receive_notice_url: 'User Update Newsletter Flag',
  user_phone_id_suspended_notice_url: 'User Phone-id Suspended',
  device_withdrawal_notice_url: 'Device Delete',
  device_modify_notice_url: 'Device Update',
  device_create_notice_url: 'Device Create',
  linking_register_notice_url: 'Linking Register',
  linking_withdrawal_notice_url: 'Linking Withdrawal',
  developer_login_id: 'Developer Emails',
};

export const WEBHOOK_EVENT_TYPE = {
  user_create_notice_url: 'userCreate',
  user_valid_email_notice_url: 'userEmailValidation',
  user_modify_notice_url: 'userUpdate',
  user_modify_login_id_notice_url: 'userUpdateLoginId',
  user_withdrawal_notice_url: 'userDelete',
  user_name_check_notice_url: 'userNameCheck',
  user_expire_token_notice_url: 'userExpireToken',
  user_unused_notice_url: 'userUnused',
  user_unlock_notice_url: 'userUnlock',
  user_phone_id_suspended_notice_url: 'userPhoneIDSuspended',
  user_tnc_notice_url: 'userTnc',
  user_notify_newsletter_receive_notice_url: 'userUpdateNewsLetter',
  device_create_notice_url: 'deviceCreate',
  device_modify_notice_url: 'deviceUpdate',
  device_withdrawal_notice_url: 'deviceDelete',
  linking_register_notice_url: 'linkingRegister',
  linking_withdrawal_notice_url: 'linkingWithdrawal',
};

export const ABOUT_LAST_USAGE = 'Credential usage may take up to 24 hours to be updated';
