import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cc-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {

  @Input() public checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  public handleChange(event) {
    this.checked = !this.checked;
    this.checkedChange.emit(event.target.checked);
  }

}
