<div class="package-input" *ngIf="!hide">
  <div *ngIf="clientType === CLIENT_TYPE.TIZEN || clientType === CLIENT_TYPE.ANDROID">
    <div class="package-name-wrapper">
      <cc-input-text
        class="package-name"
        title="Package name"
        placeholder="Create package name"
        [control]="formGroupControls(currentPackage, 'package_info')"
        [isSubmitted]="isSubmitted"
      ></cc-input-text>
  

      <sdc-design-button
      class="button delete-btn"
      [title]="'Remove this package'"
      outline="true"
      [disabled]="checkPackagesToDisableRemoveButton"
      type="warn"
      width="194px"
      height="30px"
      (click)="removeRow()"
    ></sdc-design-button>

    </div>
    <div class="wrapper-package">
      <div class="quick-help" (click)="handleOpenQuickHelp()" *ngIf="hasQH">
        <cc-icon
          width="24px"
          height="24px"
          [iconUrl]="'/assets/images/icons/hint-icon.png'"
        ></cc-icon>
        <span>Quick Help</span>
      </div>
      <cc-input-text
        class="contain-quickhelp"
        title="Signature Code (Short)"
        [placeholder]="
          'Enter maximum ' + (isTizen ? '45' : '15') + ' digit signature code'
        "
        [control]="formGroupControls(currentPackage, 'short_signature')"
        [isSubmitted]="isSubmitted"
        hint="Do not use Google Signing Key to generate the Signature Code."
        showHintOn="focus"
        [showErrorOnFocus]="true"
      ></cc-input-text>
    </div>
    <cc-input-text
      title="Signature Code (Long)"
      [placeholder]="
        'Enter maximum ' + (isTizen ? '256' : '5000') + ' digit signature code'
      "
      [control]="formGroupControls(currentPackage, 'long_signature')"
      [isSubmitted]="isSubmitted"
      hint="Do not use Google Signing Key to generate the Signature Code."
      showHintOn="focus"
      [showErrorOnFocus]="true"
    ></cc-input-text>
  </div>

  <div *ngIf="clientType === CLIENT_TYPE.WINDOWS">
    <div class="package-name-wrapper">
      <cc-input-text
        class="package-name"
        title="Package name" 
        placeholder="Create package name"
        [control]="formGroupControls(currentPackage, 'package_name')"
        [isSubmitted]="isSubmitted"
      ></cc-input-text>

      <sdc-design-button
      class="button delete-btn"
      [title]="'Remove this package'"
      outline="true"
      [disabled]="checkPackagesToDisableRemoveButton"
      type="warn"
      width="194px"
      height="30px"
      (click)="removeRow()"
    ></sdc-design-button>

    </div>
    <cc-input-text
      title="App Hash Code" 
      placeholder="Enter maximum 256 digit hash code"
      [control]="formGroupControls(currentPackage, 'app_hash_code')"
      [isSubmitted]="isSubmitted"
      hint="Do not use Google Signing Key to generate the Key Hash."
      showHintOn="focus" 
      [showErrorOnFocus]="true"
    ></cc-input-text>
  </div>

  <div *ngIf="maxSize > 1" style="width: 100%; text-align: center">
    <div style="display: flex; justify-content: center">
      <ul class="packages-pagination">
        <li
          *ngFor="let group of formArrayGroups; let i = index"
          class="packages-pagination__item"
          [class.packages-pagination__item--active]="currentPackage === i"
          (click)="setCurrentPackage(i)"
        >
          {{ i + 1 }}
        </li>
      </ul>

      <sdc-design-button
      class="button-add-package"
      [title]="'Add Package'"
      outline="true"
      [disabled]="formArrayGroups.length >= maxSize"
      type="primary"
      width="134px"
      height="40px"
      (click)="addRow()"
    ></sdc-design-button>

    </div>
    <span *ngIf="!controlArray.valid && isSubmitted" style="color: red;">One or more packages was not filled in correctly.</span>
  </div>

</div>
