import { Component, EventEmitter, Inject, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmTextValidator } from 'src/app/common/form-validator';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit, OnChanges {
  id: string = '';
  title: string = 'Attention!';
  content: string = '';
  confirmButtonTitle: string = 'Proceed';
  confirmButtonColor: string = 'red';
  useConfirmWord: boolean = false;
  confirmWord: string = 'I do';
  // show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @Output() public clickHandler: EventEmitter<void> = new EventEmitter();
  @Output() public clickBackButtonHandler: EventEmitter<void> = new EventEmitter();
  form: FormGroup;
  isSubmitted: boolean = false;
  showBackButton: boolean = false;

  constructor(protected fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.loadDialogInfo();
  }

  ngOnChanges(): void {
    if (this.form) {
      this.form.controls.confirmPass.setValidators([ConfirmTextValidator(this.confirmWord)]);
      this.form.controls.confirmPass.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      confirmPass: this.fb.control('', ConfirmTextValidator(this.confirmWord)),
    });
  }

  loadDialogInfo() {
    this.id = this.dialogData.id;
    this.title = this.dialogData.title ? this.dialogData.title : this.title;
    this.content = this.dialogData.content;
    this.useConfirmWord = this.dialogData.useConfirmWord;
    this.confirmWord = this.dialogData.confirmWord;
    this.confirmButtonTitle = this.dialogData.confirmButtonTitle
      ? this.dialogData.confirmButtonTitle
      : this.confirmButtonTitle;
    this.confirmButtonColor = this.dialogData.confirmButtonColor
      ? this.dialogData.confirmButtonColor
      : this.confirmButtonColor;
    this.showBackButton = this.dialogData.showBackButton;
  }

  handleClickButton() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.showChange.emit(false);
      this.form.reset();
      this.clickHandler.emit();
    }
    if (!this.useConfirmWord) {
      this.clickHandler.emit();
    }
  }

  handleButtonType() {
    return this.confirmButtonColor === 'red' ? 'warn' : 'primary';
  }

  handleButtonOutline() {
    return this.confirmButtonColor === 'red' ? true : false;
  }

  handleClickBackButton() {
    this.clickBackButtonHandler.emit();
  }
}
