import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { ErrorMessagesService } from '../../../service/common';
import { RedirectUrlValidator, CustomRedirectUrlValidator } from '../../form-validator';
import { CLIENT_TYPE, ERROR, REDIRECT_URL } from 'src/app/common/constant/client.constant';
import { ServiceDomainValidator } from '../../form-validator/service-domain.validator';
@Component({
  selector: 'cc-multiple-input',
  templateUrl: './multiple-input.component.html',
  styleUrls: ['./multiple-input.component.scss'],
})
export class MultipleInputComponent implements OnChanges {
  @Input() controlArray: FormArray;
  @Input() clientType = '';
  @Input() initialValue: string[] = [];
  @Input() unlimitedSize: boolean = false;
  @Input() title = '';
  @Input() hint = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() isSubmitted = false;
  @Input() passKey = '';
  @Input() errorMessage = '';
  @Input() list = [];
  @Input() maxLength = Number.MAX_SAFE_INTEGER;
  @Input() maxListSize = Number.MAX_SAFE_INTEGER;
  @Input() hide = false;
  @Input() isAllTypeOAuth = false;
  @Input() isReadOnly = false;
  @Input() clientMigration = false;
  @Input() serviceDomainValidaion = false;
  @Output() listChange: EventEmitter<any> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();
  @Output() newInputAdded: EventEmitter<any> = new EventEmitter();
  private limitReached: boolean = false;
  private limitErrorMessage: string = ERROR.LIMIT_DEFAULT;
  private limitExceedFlag: boolean = false;
  constructor(protected fb: FormBuilder, private errorMessages: ErrorMessagesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue?.currentValue) {
      this.controlArray.clear();
      changes.initialValue.currentValue.forEach((url: string) => this.addRow(url));

      if (changes.initialValue.currentValue.length === 0) {
        this.addRow();
      }
    } else if (changes.clientType) {
      this.controlArray.clear();
      if (!this.hide) {
        this.addRow();
      }
    }
    this.limitExceedFlag = this.initialValue?.length > this.maxListSize;
  }
  get formArrayControls(): FormControl[] {
    return this.controlArray.controls as FormControl[];
  }

  handleChange(): void {
    this.errorMessages.checkDuplicity(this.formArrayControls);
    this.listChange.emit(this.controlArray.value);
  }

  focusout(event) {
    this.focusOut.emit(event);
  }

  addRow(url = '') {
    let validator =
      !this.unlimitedSize ||
      !this.clientType ||
      this.clientType === CLIENT_TYPE.WINDOWS ||
      this.clientType === CLIENT_TYPE.IOS ||
      this.clientType === CLIENT_TYPE.NSANDROID
        ? RedirectUrlValidator()
        : CustomRedirectUrlValidator();
    validator = this.serviceDomainValidaion ? ServiceDomainValidator() : validator;
    this.newInputAdded.emit();
    this.controlArray.push(
      this.fb.control(url, [Validators.required, validator, Validators.maxLength(this.maxLength)])
    );
    this.setErrorMessage();
    this.limitReached = this.controlArray?.controls?.length >= this.maxListSize;
  }

  removeRow(index: number) {
    this.controlArray.removeAt(index);
    if (this.formArrayControls.length === 1) {
      delete this.formArrayControls[0]?.errors?.duplicated;
      this.formArrayControls[0].updateValueAndValidity();
    }
    this.errorMessages.checkDuplicity(this.formArrayControls);
    this.listChange.emit(this.controlArray.value);
    this.setErrorMessage();
    this.limitReached = this.controlArray?.controls?.length >= this.maxListSize;
    this.limitExceedFlag = this.controlArray?.controls?.length > this.maxListSize;
  }

  getDisplayAdd(i) {
    return !this.disabled && i === this.formArrayControls.length - 1 && (this.unlimitedSize || this.isAllTypeOAuth);
  }

  getDisplayRemove() {
    return !this.disabled && this.formArrayControls.length > 1;
  }

  toggleLimitExceedError(limitExceedFlag: boolean) {
    this.limitExceedFlag = limitExceedFlag;
  }

  getLimitExceedError() {
    return this.limitExceedFlag;
  }

  private setErrorMessage() {
    if (this.title === REDIRECT_URL.DEFAULT_TITLE || this.title === REDIRECT_URL.ALL_TYPE_OAUTH_TITLE) {
      if (this.initialValue?.length > this.maxListSize && this.controlArray?.controls?.length > this.maxListSize) {
        this.limitErrorMessage = ERROR.LIMIT_EXCEEDED;
      } else if (this.controlArray?.controls?.length <= this.maxListSize) {
        this.limitErrorMessage = ERROR.LIMIT_REACHED;
      }
    }
  }
}
