import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Scopes } from 'src/app/common/interfaces/common.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ScopeService {
  constructor(private http: HttpClient) {}

  getScope(protocolType: string, scopeType: string, time?: number): Observable<Scopes> {
    const t = time ? time : this.getTime();
    const params = {
      visibility: scopeType.toUpperCase(),
      t,
    };
    const protocol = protocolType === 'all-type-oauth' ? 'oauth' : protocolType;

    return this.http.get<Scopes>(`${environment.api}/v1/${protocol}/scopes`, { params });
  }

  getTime(): number {
    return new Date().getTime();
  }
}
