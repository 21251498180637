<div class="overlay__blocked" *ngIf="!closed"></div>
<div id="cookie-overlay" class="overlay" [ngClass]="closed || accepted ? 'overlay__accepted' : 'overlay__not-accepted'">
  <div class="overlay__center">
    <div class="overlay__flex container">
      <p>
        This website uses cookies to enhance your experience and our services.
        By clicking ACCEPT or to continue to browse the site, you are agreeing
        to our use of cookies. Please see our
        <a href="https://account.samsung.com/membership/terms/privacypolicy" target="blank">privacy policy.</a>
      </p>
      <button (click)="acceptCookies()">accept</button>
    </div>
  </div>
</div>
