import { formatDate } from '@angular/common';
import { KeyPair } from '../interfaces/key-pair.interface';

export function arrayToString(text: string | string[]): string {
  if (Array.isArray(text)) {
    return text.join(', ');
  }
  return text;
}

export function formatOutputDate(obj: any): any {
  if (!obj.created_date && !obj.modified_date) {
    return obj;
  }
  const newObj = { ...obj };
  if (newObj.created_date) {
    newObj.created_date = formatDate(obj.created_date, 'yyyy-MM-dd', 'EN-US').toUpperCase();
  }
  if (newObj.modified_date) {
    newObj.modified_date = formatDate(obj.modified_date, 'yyyy-MM-dd', 'EN-US').toUpperCase();
  }

  return newObj;
}

export function formatOutputDateString(date: any): string {
  return formatDate(date, 'yyyy-MM-dd', 'EN-US').toUpperCase();
}

export function generateKeyPair(): Promise<KeyPair> {
  return window.crypto.subtle
    .generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 1024,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt']
    )
    .then((keyPair: any) => {
      const generatedKeyPair: KeyPair = {
        private_key: keyPair.privateKey,
        public_key: keyPair.publicKey,
      };

      return generatedKeyPair;
    });
}

export async function exportCryptoKey(type: string, key: any): Promise<string> {
  const exported = await window.crypto.subtle.exportKey(type === 'privateKey' ? 'pkcs8' : 'spki', key);
  const exportedAsString = String.fromCharCode.apply(null, new Uint8Array(exported));
  const exportedAsBase64 = window.btoa(exportedAsString);

  return exportedAsBase64;
}

export function encodeFileName(fileName: string): string {
  return encodeURI(fileName);
}

export function bytesToBase64(bytes) {
  const value = Array.from(bytes, (codePoint: number) => String.fromCodePoint(codePoint)).join('');
  return btoa(value);
}

export function base64ToBytes(base64) {
  const value = atob(base64);
  return Uint8Array.from(value, (m) => m.codePointAt(0));
}

export function getCheckboxItemName(name: string) {
  if (name === 'photographImageFileURLText') {
    return 'profile image';
  } else if (name === 'offline.access') {
    return 'offline access';
  } else {
    return name;
  }
}
