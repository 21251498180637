<div class="checkbox-container" [ngClass]="{'point-mark': pointMark, error: shouldShowErrors()}">
  <div class="checkbox-header">
    <div *ngIf="title && !shouldShowErrors()" class="title">{{title}}</div>
    <a (click)="selectAll()" *ngIf="selectAllBtn">Select all</a>
  </div>
  <div *ngIf="shouldShowErrors()">
    <div class="errorMessage" *ngFor="let error of listOfErrors()">
      {{ error }}
    </div>
  </div>
  <div *ngIf="subtitle" class="subtitle">{{subtitle}}</div>
  <div class="checkbox">
    <label class="checkbox-item" *ngFor="let item of listCheckbox" [ngClass]="{'checked': item.checked, 'required': item.required, 'disabled': item.required || disabled}">
      <mat-checkbox [checked]="isChecked(item.id ? item.id : item.name)" [value]="item.id ? item.id : item.name" [disabled]="item.required || disabled" (change)="handleChange($event)">
        {{getItemName(item.name)}}
      </mat-checkbox>
    </label>
  </div>
  <div *ngIf="hint" class="hint">
    <cc-icon width="16px" height="16px" [iconUrl]="'/assets/images/icons/hint-icon.png'"></cc-icon>
    <span>{{hint}}</span>
  </div>
</div>

