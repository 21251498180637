import { Component, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-consent',
  templateUrl: './cookies-consent.component.html',
  styleUrls: ['./cookies-consent.component.scss']
})
export class CookiesConsentComponent {

  accepted: boolean = false;
  closed: boolean = false;
  @Output() acceptCookie: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cookieService: CookieService
  ) { }


  acceptCookies() {
    this.closeCookies();
    this.accepted = true;
    this.cookieService.set('cookie_consent', '1');
    this.acceptCookie.emit({cookie_consent: true})
  }

  closeCookies() {
    this.closed = true;
  }

}
