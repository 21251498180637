import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { AuthService } from 'src/app/service/api';

@Component({
  selector: "app-navbar",
  templateUrl: "navbar.component.html",
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() listMenu = [];
  public userInfo$ = this.authService.userInfo$;
  private sub: Subscription;
  routerEvents$: Observable<Event>;
  routerUrl: string;

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.routerUrl = this.router.url;
    this.routerEvents$ = this.router.events.pipe(
      filter((e: Event) => e instanceof NavigationEnd)
    )
    this.sub = this.routerEvents$.subscribe((ev: NavigationEnd) => this.routerUrl = ev.urlAfterRedirects);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
