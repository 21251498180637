import { OnInit, Component } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent implements OnInit {

  public quickLinkMenu = [];

  ngOnInit(): void {
    this.initNavbar();
  }

  private initNavbar() {
    this.quickLinkMenu = [
      {
        name: 'Privacy policy',
        routerLink: '/privacy-policy'
      },
      {
        name: 'Notices',
        routerLink: '/notices'
      },
      {
        name: 'Contact Us',
        routerLink: '/contact-us'
      }
    ];
  }
}
