import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CLIENT_TYPE } from '../../constant';
import { HashcodeAndKeyValidator } from '../../form-validator';
import { PackageClient } from '../../interfaces/client.interface';
import { PackageNameValidator } from '../../form-validator/package.name.validator';

@Component({
  selector: 'cc-package-input',
  templateUrl: './package-input.component.html',
  styleUrls: ['./package-input.component.scss'],
})
export class PackageInputComponent implements OnChanges {
  @Input() controlArray: FormArray;
  @Input() initialValue: PackageClient[] = [];
  @Input() maxSize = 1;
  @Input() clientType = '';
  @Input() isSubmitted = false;
  @Input() hide = false;
  @Input() hasQH = false;
  @Output() openQuickHelp = new EventEmitter<boolean>();
  @Output() newPackageAdded = new EventEmitter<any>();
  CLIENT_TYPE = CLIENT_TYPE;
  currentPackage = 0;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue?.currentValue) {
      this.controlArray.clear();
      this.initialValue.forEach((pack: PackageClient) => this.addRow(pack));
      this.currentPackage = 0;

      if (changes.initialValue.currentValue.length === 0) {
        this.addRow();
      }
    } else if (changes.clientType) {
      this.controlArray.clear();
      this.currentPackage = 0;
      this.addRow();
    }
  }

  get formArrayGroups(): FormGroup[] {
    return this.controlArray.controls as FormGroup[];
  }

  get isTizen(): boolean {
    return this.clientType === CLIENT_TYPE.TIZEN;
  }

  get checkPackagesToDisableRemoveButton(): boolean {
    const form = this.controlArray.value[0];
    return (
      this.controlArray.length === 1 &&
      (((form.long_signature === '' || form.long_signature === null) &&
        (form.package_info === '' || form.package_info === null) &&
        (form.short_signature === '' || form.short_signature === null)) ||
        ((form.app_hash_code === '' || form.app_hash_code === null) &&
          (form.package_name === '' || form.package_name === null)))
    );
  }

  formGroupControls(index: number, control: string): FormControl {
    return this.formArrayGroups[index]?.controls[control] as FormControl;
  }

  addRow(pack = null as PackageClient) {
    this.newPackageAdded.emit();
    this.addPackage(pack, this.clientType);
    this.setCurrentPackage(this.controlArray.length - 1);
  }

  removeRow(): void {
    if (this.controlArray.length === 1) {
      this.formArrayGroups[0].reset();
      return;
    }

    this.controlArray.removeAt(this.currentPackage);

    if (this.currentPackage) {
      this.setCurrentPackage(this.currentPackage - 1);
    }
  }

  setCurrentPackage(index: number): void {
    this.currentPackage = index;
  }

  handleOpenQuickHelp() {
    this.openQuickHelp.emit(true);
  }

  private addPackage(pack: PackageClient, clientType: string) {
    const addRowTizenAndroid = (maxShort: number, maxLong: number): FormGroup => {
      return this.fb.group({
        package_info: this.fb.control(pack ? pack.package_info : '', [
          Validators.required,
          Validators.maxLength(100),
          PackageNameValidator(),
        ]),
        short_signature: this.fb.control(pack ? pack.short_signature : '', [
          Validators.required,
          Validators.maxLength(maxShort),
          ...HashcodeAndKeyValidator('short', this.clientType),
        ]),
        long_signature: this.fb.control(pack ? pack.long_signature : '', [
          Validators.required,
          Validators.maxLength(maxLong),
          ...HashcodeAndKeyValidator('long', 'any'),
        ]),
      });
    };

    const addRowWindows = (): FormGroup => {
      return this.fb.group({
        package_name: this.fb.control(pack ? pack.package_name : '', [
          Validators.required,
          Validators.maxLength(100),
          PackageNameValidator(),
        ]),
        app_hash_code: this.fb.control(pack ? pack.app_hash_code : '', [
          Validators.required,
          Validators.maxLength(256),
          ...HashcodeAndKeyValidator('short', 'Windows'),
        ]),
      });
    };

    const packages = {
      [CLIENT_TYPE.TIZEN]: () => addRowTizenAndroid(45, 256),
      [CLIENT_TYPE.ANDROID]: () => addRowTizenAndroid(15, 5000),
      [CLIENT_TYPE.WINDOWS]: addRowWindows,
      default: () => null,
    };

    const newPackage: FormGroup = (packages[clientType] || packages['default'])();

    if (!newPackage) {
      return;
    }

    this.controlArray.push(newPackage);
  }
}
