import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ErrorMessagesService } from '../../../service/common';

@Component({
  selector: 'cc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnChanges {
  @Input() title = '';
  @Input() hint = '';
  @Input() defaultLabel = 'Dropdown';
  @Input() dropdownMenu = [];
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() isSubmitted = false;
  @Input() errorMessage = '';
  @Input() control: FormControl;
  @Input() value = '';
  @Input() selectedValues = [];
  @Input() disableSelectedItems = [];
  @Input() projectDropdown: boolean = false;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  public changedOnce = false;
  isSelectedValue: boolean = false;
  isOutIcon: boolean = true;
  isOutItem: boolean = true;
  isOutMenu: boolean = true;
  animationState: string = 'out';

  constructor(protected fb: FormBuilder, private errorMessages: ErrorMessagesService) {}

  ngOnChanges(): void {
    if (this.dropdownMenu && this.dropdownMenu.length > 0 && typeof this.dropdownMenu[0] === 'string') {
      this.dropdownMenu = this.formatStringMenu(this.dropdownMenu);
    }
    if (this.hasAnyError) {
      this.control.markAsTouched();
    }
  }

  get selectedName(): string {
    return this.dropdownMenu.find((item) => item.id === this.control?.value)?.name || '';
  }

  get hasAnyError() {
    return this.shouldShowErrors() || this.errorMessage || (!this.errorMessage && this.shouldShowErrors());
  }

  handleChange(item: any): void {
    this.valueChange.emit(item.value);
  }

  shouldShowErrors(): boolean {
    return this.errorMessages.shouldShowErrors(this.control, this.isSubmitted);
  }

  listOfErrors(): string[] {
    return this.errorMessages.listOfErrors(this.control, this);
  }

  handleDisableItem(item: any) {
    let disabled = false;
    this.selectedValues.forEach((res) => {
      if (res.event === item.id) {
        disabled = true;
        return;
      }
    });
    return disabled && this.disableSelectedItems;
  }

  private formatStringMenu(arr: string[]) {
    return arr.map((item: string) => ({
      id: item,
      name: item,
    }));
  }
}
