import { ValidatorFn, AbstractControl } from '@angular/forms';

export function UrlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9:_\.\+=]{2,256}\.[a-z]{2,6}([-a-zA-Z0-9@:%_\.\+?&/=]*)$/;
    if (!!control.value) {
      if (!regex.test(control.value)) {
        return { url: true };
      }
    }
    return null;
  };
}
