<div class="warning" *ngIf="warningHead && warningMessage">
  <a class="close" [mat-dialog-close]>
    <img class="close-icon" src="/assets/images/icons/close-icon.png" alt="close-icon" />
  </a>
  <div class="warning-head">
    {{ warningHead }}
  </div>
  <div class="warning-body" [innerHTML]="warningMessage"></div>
  <div class="center">
    <sdc-design-button
      type="warn"
      title="Dismiss"
      outline="true"
      width="120px"
      height="40px"
      [mat-dialog-close]
    ></sdc-design-button>
  </div>
</div>
