import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private subject = new BehaviorSubject<boolean>(false);
  private loadingList: string[] = [];

  getLoading(): Observable<boolean> {
    return this.subject.asObservable();
  }

  show(loadingUrl: string) {
    if (loadingUrl && loadingUrl != '') {
      this.loadingList.push(loadingUrl);
      this.subject.next(true);
    }
  }

  hide(loadingUrl: string) {
    this.loadingList.splice(this.loadingList.indexOf(loadingUrl), 1);
    if (!this.loadingList || this.loadingList.length == 0) {
      this.subject.next(false);
    }
  }
}
