import { OnInit, Component, Input } from '@angular/core';

@Component({
  selector: 'cc-loading-widget',
  templateUrl: './loading-widget.component.html',
  styleUrls: ['./loading-widget.component.scss'],
})
export class LoadingWidgetComponent {
  @Input() width = 40;
}
