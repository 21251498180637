<div class="radio-container" [ngClass]="{ error: shouldShowErrors() }">
  <div *ngIf="title" class="title">{{ title }}</div>
  <mat-radio-group aria-label="Select an option" *ngIf="list">
    <mat-radio-button *ngFor="let item of listRadio"
    class="form-input"
    [value]="item.value"
    [checked]="control ? control.value === item.value : value === item.value"
    [name]="name"
    (input)="handleChange($event)"
    [disabled]="disabled"
    >{{ item.name }}</mat-radio-button>
  </mat-radio-group>
  <mat-radio-button *ngIf="!list"
    class="form-input"
    [value]="value"
    [checked]="control.value === value"
    [name]="name"
    (input)="handleChange($event)"
    [disabled]="disabled">{{ name }}
  </mat-radio-button>
  <div *ngIf="shouldShowErrors()">
    <div class="errorMessage" *ngFor="let error of listOfErrors()">
      {{ error }}
    </div>
  </div>
  <div
    *ngIf="hint"
    class="hint"
    [ngClass]="{
      showHintOnHover: showHintOn == 'hover',
      showHint: showHintOn == ''
    }"
  >
    <cc-icon
      width="16px"
      height="16px"
      [iconUrl]="'/assets/images/icons/hint-icon.png'"
    ></cc-icon>
    <span>{{ hint }}</span>
  </div>
</div>


