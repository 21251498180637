import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectOption } from 'src/app/common/interfaces/common.interface';
import { environment } from 'src/environments/environment';
import { WindowService } from './window.service';

@Injectable({ providedIn: 'root' })
export class CommonService {
  private _window: WindowService;
  constructor(private http: HttpClient, private windowService: WindowService) {
    this._window = this.windowService;
  }

  getLocaleList(): Observable<SelectOption[]> {
    return this.http
      .get<string[]>(`${environment.api}/v1/common/locales`)
      .pipe(map((res: string[]) => this.stringToSelectOption(res)));
  }

  getCountryCodes(): Observable<SelectOption[]> {
    return this.http.get<any[]>(`${environment.api}/v1/common/countries`).pipe(
      map((res: any) => {
        const countryCodes = res.map((country: any) => {
          return country.alpha3;
        });
        return this.stringToSelectOption(countryCodes);
      })
    );
  }

  getCountryList(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/v1/common/countries`);
  }

  getLanguageList(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/v1/common/languages`);
  }

  getContentTypes(): Observable<SelectOption[]> {
    const contentTypes = ['Application/json', 'Text/xml'];
    return of(contentTypes).pipe(map((res: string[]) => this.stringToSelectOption(res, true)));
  }

  getClientTypeList(protocolType: string): Observable<any[]> {
    const params = { protocol_type: protocolType };
    return this.http.get<any[]>(`${environment.api}/v1/common/client-types`, { params });
  }

  getBusinessList(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.api}/v1/common/business-types`);
  }

  codeGenerator(): void {
    this._window.open(`${environment.api}/v1/common/code-generator`, '_self');
  }

  redirectOverview(): void {
    this._window.open(`${environment.documentation}/overview/`, '_blank');
  }

  stringToSelectOption(list: string[], lowerCase: boolean = false): SelectOption[] {
    return list.map((item: string) => ({
      id: lowerCase ? item.toLowerCase() : item,
      name: item,
    }));
  }
}
