import { Component, Input, OnInit, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'cc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  /**
   * Row template
   */
  @ContentChild('body') bodyTemplate: TemplateRef<any>;

  /**
   * Array of columns definition
   * @type {Array}
   *
   * Ex:
    columnDef = [
      { headerName: "Name", field: "projectName" },
      { headerName: "Project ID", field: "projectId" },
      { headerName: "Project Secret Key", field: "projectSecret" },
      { headerName: "3rd Party", field: "projectPartyType" },
      { headerName: "Created", field: "createdTime" },
      { headerName: "Expired", field: "modifiedTime" }
    ];
   */
  @Input() columnDef = [];

  /**
   * Array of items
   * @type {Array}
   */
  @Input() items = [];

  @Input() useIndex = false;
}
