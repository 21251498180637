import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'cc-scrollspy-menu',
  templateUrl: './scrollspy-menu.component.html',
  styleUrls: ['./scrollspy-menu.component.scss'],
})
export class ScrollspyMenuComponent implements OnChanges {
  @Input() menu = []; // {name: '', fragment: '', child: [{name: '', fragment: '', child: []}]}
  @Input() currentSection = '';
  showChild = [];
  section = '';

  ngOnChanges() {
    this.section = this.currentSection;
    if (this.menu.length) {
      this.menu.forEach(() => {
        this.showChild.push(false);
      });
    }
  }
}
