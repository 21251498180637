import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { GraphqlError } from 'src/app/common/interfaces/error-graphql.interface';
import { environment } from 'src/environments/environment';
import { AuthService, WindowService } from '../api';
import { AlertService } from './alert.service';
import { LoadingService } from './loading.service';
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private loadingService: LoadingService,
    private tokenExtractor: HttpXsrfTokenExtractor,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private _window: WindowService,
    private alertService: AlertService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequest!: HttpRequest<any>;
    const xsrfMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];
    const headerName = 'X-XSRF-TOKEN';
    const token: string = this.tokenExtractor.getToken();

    newRequest = request.clone({ withCredentials: true });
    if (token && xsrfMethods.some((method) => request.method === method) && !request.headers.has(headerName)) {
      newRequest = request.clone({
        headers: request.headers.set(headerName, token),
        withCredentials: true,
      });
    }

    this.loadingService.show(request.url);

    return next.handle(newRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const body = event.body;

          if (body && body.errors && body.errors.length > 0) {
            body.errors.forEach((err: GraphqlError) => {
              this.handleError(err.extensions, request.url);
            });
          }
        }
        return event;
      }),
      catchError((err) => this.handleError(err, request.url)),
      finalize(() => this.loadingService.hide(request.url))
    );
  }

  private handleError(err, url: string) {
    const privateRoutes = ['project', 'user'];
    const isAuthRequest = url.includes('/auth/user');
    const isIpCheck = url.includes('/ip/check');
    const error_code = this.route.snapshot.queryParams.error_code;

    this.loadingService.hide(url);

    const handleErrorCode = decodeURIComponent(decodeURIComponent(this.router.url))
      .split('?')
      .filter((item) => {
        return item.includes('error_code');
      });

    let decodedErrorCode = '';
    if (handleErrorCode && handleErrorCode.length > 0) {
      const errorCodeSplit = handleErrorCode[0].split('=');
      decodedErrorCode = errorCodeSplit && errorCodeSplit.length > 0 ? errorCodeSplit[errorCodeSplit.length - 1] : '';
    }

    if (isAuthRequest && (error_code === 'AUT004' || decodedErrorCode === 'AUT004')) {
      this.router.navigate(['/phone-id-not-supported']);
      return null;
    }

    switch (err.status) {
      case 401:
        if (isAuthRequest) {
          this.goToAuthorize(privateRoutes);
        }
        break;
      case 400:
        break;
      case 403:
        if (isAuthRequest) {
          this.goToAuthorize(privateRoutes);
        } else if (isIpCheck) {
          this.router.navigate(['/unregistered-ip']);
        } else {
          if (this.hasEmptyErrorCode(err)) {
            const tagIdS3Url: string = 'https://tagid-developers-documents.s3.eu-central-1.amazonaws.com';

            if (err.url.includes(tagIdS3Url)) {
              this.authService.userSubject.next(null);
              this.alertService.error('Review Security Result Download Error');
              return null;
            }

            // if (url.includes('all-type-oauth/clients') || err.error.code === 'PRJ002') {
            //   this.alertService.error(
            //     err.error.message ? err.error.message : 'An error occurred while processing your request!'
            //   );
            //   return null;
            // }

            if (this.router.url.includes('email-invite') || this.router.url.includes('email-invalid')) {
              this.authService.userSubject.next(null);
              this.redirectToHome();
              return null;
            }

            if (!this.router.url.includes('resources')) {
              this.authService.userSubject.next(null);
              this.redirectToPreviousPage();
              return null;
            }
          }
        }
        break;
      case 503:
        this.router.navigate(['/service-unavailable']);
        break;
    }
    return throwError(err);
  }

  hasEmptyErrorCode(err: any): boolean {
    return err && !err.code;
  }

  goToAuthorize(privateRoutes: string[]) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart || event instanceof NavigationError) {
        if (privateRoutes.some((route) => event.url.includes(route))) {
          this.authService.userSubject.next(null);
          this.redirectToHome();
        }
      }
    });
  }

  redirectToPreviousPage() {
    this._window.open(`${environment.api}/oauth2/authorization/samsung?redirect_uri=${window.location.href}`, '_self');
  }

  redirectToHome() {
    this._window.open(
      `${environment.api}/oauth2/authorization/samsung?redirect_uri=${window.location.protocol}//${window.location.host}`,
      '_self'
    );
  }
}
