import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/service/common';
import { Subscription } from 'rxjs';

@Component({ 
  selector: 'app-alert',
  templateUrl: 'alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  message: any;

  constructor(private alertService: AlertService) { }

  ngOnInit() {
    this.sub = this.alertService.getAlert()
      .subscribe(message => {
        switch (message && message.type) {
          case 'success':
            setTimeout(() => {
              message.cssClass = 'alert-success slide-up';
            }, 0)
            setTimeout(() => {
              message.cssClass = 'alert-success';
            }, 2500)
            break;
          case 'error':
            setTimeout(() => {
              message.cssClass = 'alert-danger slide-up';
            }, 0)
            setTimeout(() => {
              message.cssClass = 'alert-danger';
            }, 2500)
            break;
        }

        this.message = message;
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
