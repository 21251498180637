import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'cc-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent {

  @Input() value = '';
  @Output() onSearchSubmit: EventEmitter<string> = new EventEmitter();

  public handleSearch() {
    this.onSearchSubmit.emit(this.value);
  }
}
