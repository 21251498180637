import { ValidatorFn, AbstractControl } from '@angular/forms';

let forbiddenKeys = [
  {
    "packageName": "verity",
    "shortKey": "-808146191",
    "longKey": "ae959405f84c9475a7907fec2d24093387ee08b61f940077c6641adcb1253701"
  },
  {
    "packageName": "testkey",
    "shortKey": "-672009692",
    "longKey": "c88c9048f6d0fe9d8561926240f2ccc1982e24721150929350384659aa54aef6"
  },
  {
    "packageName": "shared",
    "shortKey": "-1790780999",
    "longKey": "651085ea6b1e04a01eb705f558db7684d871fe971cce7f890f60813330f54d47"
  },
  {
    "packageName": "platform",
    "shortKey": "-1263674583",
    "longKey": "c13594a1465c1c7ec47e0477be849d9a46fc3100c1d7ed3456bd9679716a728f"
  },
  {
    "packageName": "media",
    "shortKey": "-1434565454",
    "longKey": "9b0702ef8db95f863a7d496bbe6d6aa415b99bc84551fea48114cc8925e78d38"
  }
];

export function ForbiddenKeyValidator(type: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let forbidden = false;
    if (type == 'short') {
      forbidden = (forbiddenKeys.filter(e => e.shortKey === control.value).length > 0);
    }
    else {
      forbidden = (forbiddenKeys.filter(e => e.longKey === control.value).length > 0);
    }
    return forbidden ? {'googleKey': {value: control.value}} : null;
  };
}
