import { OnInit, Component } from '@angular/core';

@Component({
  selector: 'app-gnb',
  templateUrl: 'gnb.component.html',
  styleUrls: ['gnb.component.scss']
})
export class GNBComponent implements OnInit {

  public gnbMenu = [];

  ngOnInit(): void {
    this.initNavbar();
  }

  private initNavbar() {
    this.gnbMenu = [
      {
        name: 'Projects',
        routerLink: '/project'
      },
      {
        name: 'Documentation',
        routerLink: '/resources'
      },
      {
        name: 'Support',
        routerLink: '/support'
      }
    ];

  }

}
