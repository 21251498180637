import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { IpService } from 'src/app/service/api/ip.service';
import { WarningService } from 'src/app/service/common';

export const handler = (e) => {
  e.stopPropagation();
  e.preventDefault();
};

@Component({
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public cookieConsent: boolean = false;
  environmentStore$: Subscription;
  currentEnvironment: any = {};
  ipAccess: boolean = false;
  constructor(
    protected router: Router,
    @Inject(DOCUMENT) private document: Document,
    protected warningService: WarningService,
    private cookieService: CookieService,
    private store: Store<{ environmentStore: any }>,
    private ipService: IpService
  ) {}

  ngOnInit() {
    this.ipService.checkAccess().subscribe((_) => (this.ipAccess = true));
    this.environmentStore$ = this.store.select('environmentStore').subscribe((payload) => {
      this.currentEnvironment = payload;
      this.cookieConsent = this.cookieService.check('cookie_consent');
      if (this.ipAccess && !this.cookieConsent) {
        this.document.getElementById('layout').addEventListener('click', handler, true);
      }
    });
  }

  updateCookie(event) {
    this.cookieConsent = this.cookieService.check('cookie_consent');
    if (event.cookie_consent) {
      this.cookieConsent = this.cookieService.check('cookie_consent');
      if (this.cookieConsent) {
        this.document.getElementById('layout').removeEventListener('click', handler, true);
      }
    }
  }

  ngAfterViewInit() {
    // Support Chrome and IE11 only
    setTimeout(() => {
      if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i)) {
        // is mobile
        this.warningService.push(
          'Mobile is not currently supported.',
          'Samsung Developer Console is best viewed on a desktop computer using Chrome.'
        );
      } else if (!navigator.userAgent.match(/Chrome/i)) {
        // is not Chrome
        this.warningService.push(
          'Your browser is not currently supported.',
          'Samsung Developer Console only supports Chrome. Please upgrade your browsing experience to this platform.'
        );
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.environmentStore$.unsubscribe();
  }
}
