import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<any>();
  private keepAfterRouteChange: boolean = false;

  constructor(private router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert message
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange: boolean = false) {
    this.dispatch(message, 'success', keepAfterRouteChange);
  }

  error(message: string, keepAfterRouteChange: boolean = false) {
    this.dispatch(message, 'error', keepAfterRouteChange);
  }

  clear() {
    this.subject.next();
  }

  private dispatch(message: string, type: string, keepAfterRouteChange: boolean) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type, text: message });
    setTimeout(() => {
      this.clear();
    }, 3000);
  }
}
