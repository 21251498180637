<div class="multiple-input" *ngIf="!hide">
  <div class="input-list">
    <div
      class="input-contain"
      *ngFor="let item of formArrayControls; let i = index"
    >
      <div class="ii-contain">
        <div class="title">
          <span *ngIf="i === 0" class="url-type">{{ title }}</span>
        </div>
        <div class="input-text">
          <cc-input-text
            [control]="formArrayControls[i]"
            [readonly]="isReadOnly"
            [placeholder]="placeholder"
            [isSubmitted]="isSubmitted"
            [disabled]="disabled"
            [value]="formArrayControls[i].value"
            (valueChange)="handleChange()"
          ></cc-input-text>
        </div>
        <div class="icon-contain">
          <cc-icon
            *ngIf="!disabled && formArrayControls.length > 1"
            [iconUrl]="'/assets/images/icons/delete-icon-ux.png'"
            (clickIcon)="removeRow(i)"
          ></cc-icon>
        </div>
        <div class="icon-contain">
          <cc-icon
            *ngIf="
              !disabled &&
              i === formArrayControls.length - 1 &&
              formArrayControls.length < maxSize
            "
            [iconUrl]="'/assets/images/icons/add-icon.png'"
            (clickIcon)="addRow()"
          ></cc-icon>
        </div>
        <button
          *ngIf="button"
          class="button-test"
          (click)="handleClick($event, i)"
        >
          <span class="text">Test</span>
        </button>
      </div>
    </div>
  </div>
</div>