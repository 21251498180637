import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizedNameInfo, LocalizedNames } from 'src/app/common/interfaces/common.interface';
import { environment } from 'src/environments/environment';

interface CommonImage {
  image_url: string;
}

@Injectable({ providedIn: 'root' })
export class ConsentService {
  constructor(private http: HttpClient) {}

  uploadIcon(formData: FormData): Observable<CommonImage> {
    return this.http.post<CommonImage>(`${environment.api}/v1/common/image`, formData);
  }

  decodeConsent(src: LocalizedNames): LocalizedNameInfo[] {
    let locale = Object.keys(src);
    return locale.map((item) => ({
      selectValue: item,
      inputValue: src[item],
    }));
  }

  encodeConsent(src: LocalizedNameInfo[]): LocalizedNames {
    let tar: LocalizedNames = {};
    for (const value of src) {
      if (value.selectValue) {
        tar[value.selectValue] = value.inputValue;
      }
    }
    return tar;
  }
}
