import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AllTypeClient,
  Client,
  ClientCreateResponse,
  ClientSecret,
  IIdpMetadata,
  IIdpMetadataUrl,
} from 'src/app/common/interfaces/client.interface';
import { Project } from 'src/app/common/interfaces/project.interface';
import { Results } from 'src/app/common/interfaces/results.interface';
import { bytesToBase64 } from 'src/app/common/utils/helper';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ClientService {
  constructor(private http: HttpClient) {}

  createClient(projectData: Project, clientData: any): Observable<ClientCreateResponse> {
    const { project_id, protocol_type } = projectData;
    const body = { project_id, ...clientData };
    const version = this.getVersion(protocol_type);

    return this.http.post<ClientCreateResponse>(`${environment.api}/${version}/${protocol_type}/clients`, body);
  }

  updateClient(protocolType: string, client: Client): Observable<Project> {
    const version = this.getVersion(protocolType);

    return this.http.put<Project>(`${environment.api}/${version}/${protocolType}/clients/${client.client_id}`, client);
  }

  getClient(protocolType: string, clientId: string): Observable<Client> {
    const version = this.getVersion(protocolType);

    return this.http
      .get<Client>(`${environment.api}/${version}/${protocolType}/clients/${clientId}`)
      .pipe(map((client: Client) => this.formatClient(client)));
  }

  getSamlIdpCertificate(clientId: string): Observable<IIdpMetadata> {
    const protocolType = 'saml';
    const version = this.getVersion(protocolType);
    return this.http.get<any>(`${environment.api}/${version}/${protocolType}/clients/${clientId}/idp-cert`);
  }

  getSamlIdpMetadataUrl(clientId: string): Observable<IIdpMetadataUrl> {
    const protocolType = 'saml';
    const version = this.getVersion(protocolType);
    return this.http.get<any>(`${environment.api}/${version}/${protocolType}/clients/${clientId}/idp/metadata-config`);
  }

  updateSamlIdpCertificate(clientId: string, body): Observable<Client> {
    const protocolType = 'saml';
    const version = this.getVersion(protocolType);
    return this.http.post<any>(`${environment.api}/${version}/${protocolType}/clients/${clientId}/idp-cert`, body);
  }

  getClientList(protocolType: string, projectId: string, time?: number): Observable<Client[]> {
    const t = time ? time : this.getTime();
    const params = {
      project_id: projectId,
      t,
    };
    const version = this.getVersion(protocolType);

    return this.http.get<Client[]>(`${environment.api}/${version}/${protocolType}/clients`, { params });
  }

  getPendingClientList(protocolType: string, projectId: string): Observable<Results<Client>> {
    const params = {
      projectId: projectId,
      state: 'PENDING',
    };
    const version = this.getVersion(protocolType);

    return this.http.get<Results<Client>>(`${environment.api}/${version}/${protocolType}/clients/requests`, { params });
  }

  resetClientSecret(protocolType: string, client: Client): Observable<ClientSecret> {
    const { client_id, client_secret } = client;
    const body = { client_secret } as ClientSecret;

    return this.http.put<ClientSecret>(`${environment.api}/v1/${protocolType}/clients/${client_id}/secret`, body);
  }

  deleteClientByID(protocolType: string, clientId: string): Observable<void> {
    const currentVersion: string = protocolType === 'saml' ? 'v2' : 'v1';
    return this.http.delete<void>(`${environment.api}/${currentVersion}/${protocolType}/clients/${clientId}`);
  }

  migrateClient(clientData: any): Observable<any> {
    const headers = this.prepareHeaders(clientData);

    return this.http.post<any>(`${environment.api}/v1/all-type-oauth/clients`, clientData, { headers });
  }

  getMigrateClient(clientId: string): Observable<AllTypeClient> {
    return this.http.get<AllTypeClient>(`${environment.api}/v1/all-type-oauth/clients/${clientId}`);
  }

  updateMigrateClient(client: AllTypeClient): Observable<void> {
    const body = this.prepareClientData(client);

    return this.http.put<void>(`${environment.api}/v1/all-type-oauth/clients/${client.client_id}`, body);
  }

  validateMigrateClient(client: any): Observable<any> {
    const headers = this.prepareHeaders(client);

    return this.http.get<any>(`${environment.api}/v1/all-type-oauth/clients/${client.client_id}/migration`, {
      headers,
    });
  }

  private getVersion(protocolType: string): string {
    return protocolType === 'oidc' ? 'v1' : 'v2';
  }

  private prepareHeaders(clientData: any): HttpHeaders {
    const token = bytesToBase64(new TextEncoder().encode(`${clientData.client_id}:${clientData.client_secret}`));

    return new HttpHeaders().set('Authorization', `Basic ${token}`);
  }

  private prepareClientData(clientData: any): any {
    const filterNonEmpty = (item: string) => item !== '';

    return {
      ...clientData,
      redirect_url: clientData.redirect_url.filter(filterNonEmpty),
      app_alias: clientData.app_alias.filter(filterNonEmpty),
    };
  }

  private formatClient(client: Client): Client {
    const formattedClient = { ...client };

    if (client.redirect_url) {
      formattedClient.redirect_url = client.redirect_url.filter((c: string) => c && c !== 'NULL');
    }
    if (client.client_secret) {
      formattedClient.client_secret = Array.isArray(client.client_secret)
        ? client.client_secret[0]
        : client.client_secret;
    }

    return formattedClient;
  }

  getTime(): number {
    return new Date().getTime();
  }
}
