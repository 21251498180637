import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name: 'scope'
})
export class ScopePipe implements PipeTransform {
    transform(value: string): any {
        if (!value || value == '') {
            return '';
        }
        if (value === 'openid') {
            return "OpenID"
        }
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}
