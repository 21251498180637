import { AbstractControl, ValidatorFn } from "@angular/forms";
import { ForbiddenKeyValidator } from "./forbidden-key.validator";

export function HashcodeAndKeyValidator(type: string, clientType: string): ValidatorFn[] {
  return [
    HashcodeValidator(type, clientType),
    ForbiddenKeyValidator(type)
  ];
}

export function HashcodeValidator(type: string, clientType: string): ValidatorFn {
  const shortCodeRegex = /^[0-9\-]*$/;
  const shortCodeWindowsRegex = /^[a-zA-Z0-9\-\/\=]*$/;
  const tizenShortCodeRegex = /^[A-Z0-9]*$/;
  const longCodeRegex = /^[a-zA-Z0-9]*$/;

  return (control: AbstractControl): { [key: string]: any } | null => {
    let regex = shortCodeRegex;

    if (type === 'short' && clientType === 'Windows') regex = shortCodeWindowsRegex;
    if (type === 'short' && clientType === 'Tizen') regex = tizenShortCodeRegex;
    else if (type === 'long') regex = longCodeRegex;
    
    if (!regex.test(control.value)) {
      return { hashcode: { type, clientType } };
    }

    return null;
  };
}
