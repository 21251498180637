import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserComponent } from './shared/layout/header/user/user.component';
import { CallbackComponent } from './component/user/callback/callback.component';
import { AppComponent } from './app.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { CommonModule } from './common/common.module';
import { RouterModule, ExtraOptions } from '@angular/router';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { GNBComponent } from './shared/layout/header/gnb/gnb.component';
import { WarningComponent } from './shared/layout/warning/warning.component';
import { AlertComponent } from './shared/layout/alert/alert.component';
import { LoadingComponent } from './shared/layout/loading/loading.component';
import { CookiesConsentComponent } from './shared/layout/cookies-consent/cookies-consent.component';
import { HttpInterceptorService } from './service/common/http-interceptor.service';
import { WindowService } from './service/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { environmentReducer } from './reducers/environment.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DesignSystemModule } from '@sdc-design-system/design-system';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 100],
  relativeLinkResolution: 'legacy',
};

export const stores = {
  environmentStore: environmentReducer,
};

export function setStoreDevTools(production: boolean): any {
  return !production
    ? [
        StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          autoPause: true,
        }),
      ]
    : [];
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    WarningComponent,
    HeaderComponent,
    FooterComponent,
    GNBComponent,
    UserComponent,
    CallbackComponent,
    AlertComponent,
    LoadingComponent,
    CookiesConsentComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    DesignSystemModule,
    HttpClientModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          component: LayoutComponent,
          loadChildren: () => import('./shared/layout/layout.module').then((m) => m.LayoutModule),
        },
        { path: 'callback', component: CallbackComponent },
      ],
      routerOptions
    ),
    BrowserAnimationsModule,
    StoreModule.forRoot(stores, {}),
    setStoreDevTools(environment.production),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    WindowService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
