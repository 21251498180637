import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ErrorMessagesService } from 'src/app/service/common';

@Component({
  selector: 'cc-checkbox-single-multiline',
  templateUrl: './checkbox-single-multiline.component.html',
  styleUrls: ['./checkbox-single-multiline.component.scss'],
})
export class CheckboxSingleMultilineComponent {
  @Input() texts: string[] = [];
  @Input() pointMark = false;
  @Input() isSubmitted = false;
  @Input() value: boolean = false;
  @Input() initialState: boolean = false;
  @Input() control: FormControl;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor(protected fb: FormBuilder, private errorMessages: ErrorMessagesService) {}

  isChecked(event): boolean {
    return event.isChecked;
  }

  handleChange(event) {
    this.valueChange.emit(event.checked);
  }

  shouldShowErrors(): boolean {
    return this.errorMessages.shouldShowErrors(this.control, this.isSubmitted);
  }

  listOfErrors(): string[] {
    return this.errorMessages.listOfErrors(this.control, this);
  }
}
