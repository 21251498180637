import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() iconUrl = '';
  @Input() iconTitle = '';
  @Input() width = '25px';
  @Input() height = '25px';
  @Input() disabled = false;
  @Input() id = "";
  @Input() hasTooltip?: boolean = false;
  @Input() tooltipPosition?: string = 'below';
  @Input() tooltipLabel?: string = '';
  @Output() clickIcon: EventEmitter<string> = new EventEmitter();

  handleClick() {
    this.clickIcon.emit();
  }
}
