<div class="common-dropdown">
  <div class="dropdown-box" [ngClass]="{ 'disabled': disabled }">
    <div *ngIf="title" class="title">{{title}}</div>
    <div (click)="handleDropdown($event)" (mouseleave)="leaveDropdown($event)">
      <a class="dropdown-btn" id="dropdownMenuButton" >
        <span class="dropdown-value" #dropdownValue [ngClass]="{'selected': controlArray?.length }">{{controlArray?.length ? selectedName : defaultLabel }}</span>
        <span class="dropdown-multiple-icon" *ngIf="controlArray?.length === 0"></span>
        <a class="add-button" *ngIf="controlArray?.length > 0">Add</a>
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="!disabled" [@slideInOut]="animationState">
        <ng-container *ngFor="let item of dropdownMultipleMenu; let i = index">
          <img 
            *ngIf="isSelected(item.id)"
            class="dropdown-item-icon" 
            src="/assets/images/icons/checkmark-icon-basic.svg" 
            alt="checkmark" />
          <a class="dropdown-item" routerLinkActive="active"
            (click)="handleChange(item)">{{item.name}}</a>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="all-selected-items">
    <ng-container *ngFor="let selected of controlArray?.value; let i=index">
      <div class="selected-item" (click)="removeItemSelected(i)">
        <div class="item-text">
          {{ selected }}
        </div>
        <img class="close" src="/assets/images/icons/close-icon.png" alt="Close">
      </div>
    </ng-container>
  </div>
</div>
