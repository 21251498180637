import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";

export function EmailValidator(domain?: string, notInvite: boolean = false): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Validators.email(control)) {
      return { email: true };
    }
    if (domain && control.value && !control.value.includes(domain) && !notInvite) {
      return { memberValidator : "Cannot invite external people to an internal project, please use @samsung.com email" };
    }
    if (domain && control.value && !control.value.includes(domain) && notInvite) {
      return { emailSamsungValidator : "Please use @samsung.com email" };
    }
    return null;
  };
}

export function DifferentEmailValidator(field): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.includes(field) && field) {
      return { differentEmail : "Email Already Registered! Please use a different email!" };
    }
    return null;
  };
}