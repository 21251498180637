import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-output-text',
  templateUrl: './output-text.component.html',
  styleUrls: ['./output-text.component.scss']
})
export class OutputTextComponent {

  @Input() title = '';
  @Input() content = '';
  @Input() typeOutput = 'value';

}
