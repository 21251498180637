import { Component, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'cc-last-usage',
  templateUrl: './last-usage.component.html',
  styleUrls: ['./last-usage.component.scss'],
})
export class LastUsageComponent {
  @Input() lastUsage: string;
  @Input() class: string;
  
  get usage(): string {
    let labelLastUsed = 'Never used';

    if (this.lastUsage) {
      const today = new Date();
      const yesterday = moment(new Date().setDate(today.getDate() - 1));

      const usage = moment(this.lastUsage.replace('.000+00:00', ''));

      const lastUsageDAY = moment().diff(usage, 'days');
      const lastUsageFormatted = moment(usage).fromNow();

      if (lastUsageDAY <= 7) {
        if (usage.isSame(today, 'd')) {
          labelLastUsed = `Last used ${lastUsageFormatted}`;
        } else if (usage.isSame(yesterday, 'd')) {
          labelLastUsed = 'Last used yesterday';
        } else {
          labelLastUsed = `Last used ${lastUsageDAY} days ago`;
        }
      } else {
        labelLastUsed = `Last used on ${moment(this.lastUsage).format('DD/MM/YYYY')}`;
      }
    }

    return labelLastUsed;
  }
}
