import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorMessagesService } from '../../../service/common';

@Component({
  selector: 'cc-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent {
  @Input() showHintOn = '';
  @Input() showErrorOnFocus = false;
  @Input() hideTitle = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() control: FormControl;
  @Input() title = '';
  @Input() id = '';
  @Input() placeholder = '';
  @Input() placeholderColor = 'default';
  @Input() hint = '';
  @Input() type = 'text';
  @Input() min = null;
  @Input() maxLength = null;
  @Input() value = '';
  @Input() inputMatIcon = '';
  @Input() errorMessage = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  @Output() enter: EventEmitter<void> = new EventEmitter();
  @Output() focusOut: EventEmitter<any> = new EventEmitter();
  @Input() isSubmitted = false;
  focus = false;

  constructor(private errorMessages: ErrorMessagesService) {}

  handleChange(event: any) {
    const value = event.target.value;
    if (this.control) {
      this.control.setValue(value.trim());
    }
    this.valueChange.emit(value.trim());
  }

  showHint(): boolean {
    return (this.hint && !this.showHintOn) || (this.hint && this.showHintOn === 'focus' && this.focus);
  }

  handleKeyEnter() {
    this.enter.emit();
  }

  handleFocus() {
    this.focus = true;
  }

  handleBlur(event: any = null) {
    this.focus = false;
    this.focusOut.emit(event);
    if (this.control) {
      this.control.markAsTouched();
    }
  }

  shouldShowErrors(): boolean {
    return this.errorMessages.shouldShowErrors(this.control, this.isSubmitted);
  }

  listOfErrors(): string[] {
    return this.errorMessages.listOfErrors(this.control, this);
  }

  handleInputIconClass(): string {
    return this.inputMatIcon ? 'input__icon_container' : '';
  }
}
