<div class="drag-drop-file-wrapper">
  <div *ngIf="title" class="title">{{title}}</div>
  <div class="uploadfilecontainer" appDragDrop (onFileDropped)="changeFile($event)">
    <input hidden type="file" #fileInput (change)="changeFile($event)" onclick="this.value=null;">
    <ng-container *ngIf="!shouldShowErrors()">
      <div *ngIf="preUpload && (!src || fileChanged) && !fileToDownload" class="pre-upload" (click)="fileInput.click()">
        <cc-icon width="34px" height="28px" [iconUrl]="'/assets/images/icons/file-icon.svg'"></cc-icon>
        <div class="text"><span>Drag and drop or </span><a>browse files</a></div>
      </div>
      <div *ngIf="!preUpload || (src && !fileChanged) || fileToDownload" class="uploading">
        <cc-icon *ngIf="!((fileLoaded && imageSrc) || src)" width="48px" height="48px" [iconUrl]="'/assets/images/icons/placeholder-icon.svg'"></cc-icon>
        <cc-icon *ngIf="(fileLoaded && imageSrc) || src" width="48px" height="48px" [iconUrl]="imageSrc || src"></cc-icon>
        <div class="file" *ngIf="!src || fileChanged">
          <div class="file-name">{{filename}}</div>
          <div class="file-size" *ngIf="fileSize">{{fileSize}} kB</div>
        </div>
        <div class="file" *ngIf="src && !fileChanged">
          <div class="file-url" *ngIf="value">{{src}}</div>
        </div>
        <div class="progress-bar" *ngIf="!src || fileChanged || fileToDownload">
          <div class="markbar" id="markbar"></div>
        </div>
        <div class="checkmark-icon" *ngIf="fileLoaded || fileToDownload">
          <cc-icon width="34px" height="28px" [iconUrl]="'/assets/images/icons/tini-checkmark-icon.svg'"></cc-icon>
        </div>
        <span class="icon-contain" *ngIf="fileLoaded || (src && !fileChanged) || fileToDownload">
          <cc-icon [id]="removeId" [iconUrl]="'/assets/images/icons/delete-icon-ux.png'" [iconTitle]="'Delete'" (clickIcon)="removeFile()"></cc-icon>
          <cc-icon [iconUrl]="'/assets/images/icons/edit-icon.png'" [iconTitle]="'Edit'" (clickIcon)="fileInput.click()"></cc-icon>
          <cc-icon *ngIf="fileToDownload" [iconUrl]="'/assets/images/icons/download-icon.svg'" [iconTitle]="'Download'" (clickIcon)="downloadFile()"></cc-icon>
        </span>
      </div>
    </ng-container>
    <div *ngIf="shouldShowErrors()" class="error">
      <cc-icon width="35px" height="35px" [iconUrl]="'/assets/images/icons/warning-icon.svg'"></cc-icon>
      <div class="errorMessage">{{errorMsg}}</div>
      <span class="icon-contain">
        <cc-icon [id]="removeId" [iconUrl]="'/assets/images/icons/delete-icon-ux.png'" [iconTitle]="'Delete'" (clickIcon)="removeFile()"></cc-icon>
        <cc-icon [iconUrl]="'/assets/images/icons/edit-icon.png'" [iconTitle]="'Edit'" (clickIcon)="fileInput.click()"></cc-icon>
      </span>
    </div>
  </div>
  <div *ngIf="hint" class="hint">
    <cc-icon width="16px" height="16px" [iconUrl]="'/assets/images/icons/hint-icon.png'"></cc-icon>
    <span>{{hint}}</span>
  </div>
</div>
