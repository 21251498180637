import { ValidatorFn, AbstractControl } from '@angular/forms';
import { TldRegex } from '../utils/regex';

let tldRegex = TldRegex;
let urlRegex = /^(www\.)?[-a-zA-Z0-9:_\.\+=]{1,256}\.[a-z]{2,6}([-a-zA-Z0-9@:%_\.\+?&/=]*)$/;
let blockRegex = /^(?!.*%23).*$/;
let blocksharp = /^(?!.*#).*$/;
let slashTest = /\//;

function isException(url) {
  const exceptions = ['localhost'];
  let localhostRegex = /(localhost?:)/;
  let localhostRegexValidation = /(localhost?\/)/;
  let beginLocalhostRegex = /^(localhost?:)/;
  if (exceptions.indexOf(url) >= 0) return true;
  if (localhostRegexValidation.test(url)) return true;
  if (localhostRegex.test(url) && beginLocalhostRegex.test(url)) {
    let split = url.split('localhost:');
    if (parseInt(split[1]) > 9 && parseInt(split[1]) < 10000) return true;
    else return false;
  }
  return false;
}

export function ServiceDomainValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value) {
      if (isException(control.value)) {
        if (!blockRegex.test(control.value) || !blocksharp.test(control.value)) {
          return { invalidCharacters: { value: control.value } };
        } else if (slashTest.test(control.value)) {
          return { invalidSlash: { value: control.value } };
        } else {
          return null;
        }
      }
      if (control.value.startsWith('http://') || control.value.startsWith('https://')) {
        return { protocolNotRequired: { value: control.value } };
      }

      return urlRegexValidator(control);
    }
    return null;
  };
}

function urlRegexValidator(control: AbstractControl) {
  if (!blocksharp.test(control.value)) {
    return { invalidCharacters: { value: control.value } };
  }
  if (!urlRegex.test(control.value)) {
    return { privateDomain: { value: control.value } };
  }
  if (!tldRegex.test(control.value)) {
    return { publicDomain: { value: control.value } };
  }
  if (!blockRegex.test(control.value)) {
    return { invalidCharacters: { value: control.value } };
  }
  if (slashTest.test(control.value)) {
    return { invalidSlash: { value: control.value } };
  }
  return null;
}
