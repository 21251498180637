import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/service/common';

@Component({ 
  selector: 'app-loading',
  templateUrl: 'loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  private sub: Subscription;
  isShow: any;

  constructor(
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.sub = this.loadingService.getLoading()
      .subscribe((status: boolean) => {	
        if (status) {	
          this.isShow = true;	
          this.document.body.classList.add("loading");
        }	
        else {	
          this.isShow = false;	
          this.document.body.classList.remove("loading");
        }	
      });
  }

  ngOnDestroy() {	
    this.sub.unsubscribe();	
  }
}
