import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name: 'protocolType'
})
export class ProtocolTypePipe implements PipeTransform {
    transform(value: string): any {
        if (!value || value == '') {
            return '';
        }
        switch (value) {
            case 'oidc':
                return "OpenID Connect";
            case 'oauth':
                return "OAuth 2.0";
            case 'all-type-oauth':
                return "OAuth 2.0";
            case 'saml':
                return "SAML";
            default:
                return "";
        }
    }
}
