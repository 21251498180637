import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormBuilder, FormArray } from '@angular/forms';
import { trigger, state, style, transition, animate, group } from '@angular/animations';

@Component({
  selector: 'cc-dropdown-multiple',
  templateUrl: './dropdown-multiple.component.html',
  styleUrls: ['./dropdown-multiple.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          'max-height': '241px',
          opacity: '1',
          visibility: 'visible',
        })
      ),
      state(
        'out',
        style({
          'max-height': '0px',
          opacity: '0',
          visibility: 'hidden',
        })
      ),
      transition('in => out', [
        group([
          animate(
            '300ms ease-in-out',
            style({
              opacity: '0',
            })
          ),
          animate(
            '300ms ease-in-out',
            style({
              'max-height': '0px',
            })
          ),
          animate(
            '300ms ease-in-out',
            style({
              visibility: 'hidden',
            })
          ),
        ]),
      ]),
      transition('out => in', [
        group([
          animate(
            '300ms ease-in-out',
            style({
              visibility: 'visible',
            })
          ),
          animate(
            '300ms ease-in-out',
            style({
              'max-height': '241px',
            })
          ),
          animate(
            '300ms ease-in-out',
            style({
              opacity: '1',
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class DropdownMultipleComponent implements OnChanges {
  @Input() title = '';
  @Input() defaultLabel = 'Choose multiple';
  @Input() dropdownMultipleMenu = [];
  @Input() isRequired = false;
  @Input() disabled = false;
  @Input() isSubmitted = false;
  @Input() controlArray: FormArray;
  @Input() initialValue: string[] = [];
  changedOnce = false;
  dropdownMultipleIconClick = false;
  selectedItems: string[] = [];
  isOutIcon: boolean = true;
  isOutItem: boolean = true;
  isOutMenu: boolean = true;
  animationState: string = 'out';

  constructor(protected fb: FormBuilder) {}

  ngOnChanges(): void {
    const values = this.controlArray.value as string[];
    if (
      this.dropdownMultipleMenu &&
      this.dropdownMultipleMenu.length > 0 &&
      typeof this.dropdownMultipleMenu[0] === 'string'
    ) {
      this.dropdownMultipleMenu = this.formatStringMenu(this.dropdownMultipleMenu);
    }
    if (this.initialValue && values.length === 0) {
      this.controlArray.clear();
      this.initialValue.forEach((initial) => {
        this.controlArray.push(new FormControl(initial));
      });
    }
    this.selectedItems = this.controlArray.value;
  }

  get selectedName(): string {
    return `${this.controlArray.length} selected`;
  }

  isSelected(itemId: string) {
    return this.controlArray.value.some((item) => item === itemId);
  }

  handleDropdown(event: any) {
    let icon: boolean = false;

    icon = event.srcElement.className.includes('dropdown-multiple-icon');

    if (this.hasElement(event)) {
      this.dropdownMultipleIconClick = !this.dropdownMultipleIconClick;

      event.srcElement.parentElement.nextElementSibling.setAttribute('style', 'display: block');
      this.animationState = this.animationState === 'out' ? 'in' : 'out';

      if (this.controlArray.length === 0) {
        this.addClassElement(event, icon);
      }
    }
  }

  hasElement(event: any): boolean {
    return event.srcElement && event.srcElement.className && event.srcElement.parentElement.nextElementSibling;
  }

  addClassElement(event: any, icon: any) {
    if (icon) {
      event.srcElement.classList.value = this.dropdownMultipleIconClick
        ? 'dropdown-multiple-icon open'
        : 'dropdown-multiple-icon';
    } else {
      event.srcElement.nextElementSibling.classList.value = this.dropdownMultipleIconClick
        ? 'dropdown-multiple-icon open'
        : 'dropdown-multiple-icon';
    }
  }

  leaveDropdown(event: any) {
    if (this.dropdownMultipleIconClick) {
      this.animationState = 'out';
      if (this.controlArray.length === 0 && event.srcElement && event.srcElement.parentElement) {
        event.srcElement.parentElement.children[1].children[0].children[1].classList.value = 'dropdown-multiple-icon';
      }
      this.dropdownMultipleIconClick = !this.dropdownMultipleIconClick;
    }
  }

  handleChange(selectedItem: any): void {
    const values = this.controlArray.value as string[];
    const existItem = values.find((item) => item === selectedItem.id);
    if (existItem) {
      const remainItems = values.filter((item) => item !== selectedItem.id);
      this.controlArray.clear();
      remainItems.forEach((item) => this.controlArray.push(new FormControl(item)));
    } else {
      this.controlArray.push(new FormControl(selectedItem.id));
    }
    this.selectedItems = this.controlArray.value;
  }

  removeItemSelected(index: number) {
    this.selectedItems.splice(index, 1);
    this.controlArray.clear();
    this.selectedItems.forEach((i) => this.controlArray.push(new FormControl(i)));
  }

  private formatStringMenu(arr: string[]) {
    return arr.map((item: string) => ({
      id: item,
      name: item,
    }));
  }
}
