import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberInfo } from 'src/app/common/interfaces/user.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MemberService {
  constructor(private http: HttpClient) {}

  getMembersOfProject(projectId: string, time?: number): Observable<MemberInfo[]> {
    const t = time ? time : this.getTime();
    const params = {
      project_id: projectId,
      t,
    };
    return this.http.get<MemberInfo[]>(`${environment.api}/v1/members`, { params });
  }

  inviteMember(projectId: string, data: any): Observable<any> {
    const body = { project_id: projectId, ...data };
    return this.http.post<any>(`${environment.api}/v1/members`, body);
  }

  updateMember(memberId: string, data: any): Observable<MemberInfo> {
    return this.http.put<MemberInfo>(`${environment.api}/v1/members/${memberId}`, data);
  }

  deleteMember(memberId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api}/v1/members/${memberId}`);
  }

  replaceOwner(currentOwner: string, nextOwner: string): Observable<void> {
    const data = {
      current_owner: {
        member_id: currentOwner,
      },
      next_owner: {
        member_id: nextOwner,
      },
    };
    return this.http.patch<void>(`${environment.api}/v1/members`, data);
  }

  getTime(): number {
    return new Date().getTime();
  }
}
