<div id="layout" class="app-container">
  <div class="head-content">
    <div class="head-tools">
      <app-header></app-header>
      <app-gnb></app-gnb>
    </div>
    <div class="layout-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-alert></app-alert>
  <app-loading></app-loading>
  <app-footer></app-footer>
</div>
<ng-container *ngIf="!cookieConsent && ipAccess">
  <app-cookies-consent (acceptCookie)="updateCookie($event)"></app-cookies-consent>
</ng-container>
