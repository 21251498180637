import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { WarningComponent } from 'src/app/shared/layout/warning/warning.component';

@Injectable({ providedIn: 'root' })
export class WarningService {
  constructor(private dialog: MatDialog) {}

  private subject = new Subject<any>();
  getWarning(): Observable<any> {
    return this.subject.asObservable();
  }

  push(head: string, message: string) {
    this.subject.next({ type: 'warning', head: head, body: message });
    if (head && message) {
      this.dialog.open(WarningComponent, {
        width: '580px',
        panelClass: 'warning',
        autoFocus: false,
        restoreFocus: false,
        id: 'warningModal',
        data: {
          header: head,
          message: message,
        },
      });
    }
  }

  clear() {
    this.subject.next();
  }
}
