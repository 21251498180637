import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name: 'serviceType'
})
export class ServiceTypePipe implements PipeTransform {
    transform(value: string): any {
        if (!value || value == '') {
            return '';
        }
        switch (value) {
            case 'second_party':
                return "Internal";
            case '3rd_party':
                return "3rd Party";
            case 'third_party':
                return "3rd Party";
            default:
                return "";
        }
    }
}
