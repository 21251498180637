import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'cc-checkbox-single',
  templateUrl: './checkbox-single.component.html',
  styleUrls: ['./checkbox-single.component.scss'],
})
export class CheckboxSingleComponent {
  @Input() text = '';
  @Input() pointMark = false;
  @Input() isSubmitted = false;
  @Input() value: boolean = false;
  @Input() initialState: boolean = false;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor(protected fb: FormBuilder) {}

  isChecked(event): boolean {
    return event.isChecked;
  }

  handleChange(event) {
    this.valueChange.emit(event.checked);
  }
}
