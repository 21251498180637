<div class="checkbox-container" [ngClass]="{ 'point-mark': pointMark }">
  <div class="checkbox">
    <label class="checkbox-item">
      <mat-checkbox [checked]="initialState" [value]="true" (change)="handleChange($event)">
        <div class="mat-checkbox-label" *ngFor="let text of texts">{{ text }}</div>
        <div *ngIf="shouldShowErrors()">
          <div class="errorMessage" *ngFor="let error of listOfErrors()">
            {{ error }}
          </div>
        </div>
      </mat-checkbox>
    </label>
  </div>
</div>
