import { ValidatorFn, AbstractControl } from '@angular/forms';

export function LocationValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = /^(\-?([0-8]?[0-9](\.\d+)?|90(.[0]+)?)\s?[,]\s?)+(\-?([1]?[0-7]?[0-9](\.\d+)?|180((.[0]+)?)))$/;
    if (!!control.value) {
      if (!regex.test(control.value)) {
        return { location: true };
      }
    }
    return null;
  };
}
