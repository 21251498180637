import { Directive, Input, EventEmitter, Output, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[scrollSpy]',
})
export class ScrollSpyDirective {
  @Input() public spiedTags = [];
  @Output() public sectionChange = new EventEmitter<string>();
  currentSection: string;

  constructor(private _el: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    let currentSection: string;
    const children = this._el.nativeElement.querySelectorAll('.scrollspy');
    const scrollTop = document.documentElement.scrollTop;

    children.forEach((element: any) => {
      if (element.offsetTop <= scrollTop) {
        currentSection = element.id;
      } else if (scrollTop <= children[0].offsetTop) {
        currentSection = children[0].id;
      }
    });
    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }
  }
}
