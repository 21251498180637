import { OnInit, Component } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/service/api';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isTimeout: boolean = false;

  navRouterEnd: string = '';

  constructor(private authService: AuthService, private router: Router) {
    this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd),
        tap((navEnd: NavigationEnd) => {
          this.navRouterEnd = navEnd.url;
          if (!navEnd.url.includes('/project/create')) {
            sessionStorage.removeItem('@SDC_project_dev_review');
          }
          if (navEnd.url === '/timeout' || navEnd.url.includes('email-invite') || navEnd.url === '/unregistered-ip') {
            this.isTimeout = true;
          } else {
            this.isTimeout = false;
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (!this.navRouterEnd.includes('email-invite') && !this.navRouterEnd.includes('email-invalid')) {
      this.authService.getUserInfo().subscribe();
    }
  }

  get shouldDisplayUserIcon(): boolean {
    return !this.isTimeout;
  }
}
