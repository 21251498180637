import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { updateEnvironmentAction } from './actions/environment.action';
import { AuthService } from './service/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
  authUser: Subscription;
  authEnvironment: Subscription;
  resolved_feature_toggle: any;

  constructor(private store: Store<{ environmentStore: any }>, private authService: AuthService) {}

  ngOnInit(): void {
    this.authUser = this.authService.isAuthenticated().subscribe((isAuth: boolean) => {
      if (isAuth) {
        this.updateEnvironment();
      }
    });
  }

  updateEnvironment() {
    this.authEnvironment = this.authService.getEnvironmentFields().subscribe((environment_features: any) => {
      this.resolved_feature_toggle = environment_features.map((feature_toggle) => {
        return {
          [`${feature_toggle.feature}`]: feature_toggle.enabled,
        };
      });

      const env: any = {
        payload: {},
      };

      Object.values(this.resolved_feature_toggle).forEach((field) => {
        env.payload = Object.assign(env.payload, field);
      });
      this.store.dispatch(updateEnvironmentAction(env));
    });
  }

  ngOnDestroy(): void {
    this.authUser.unsubscribe();
    this.authEnvironment.unsubscribe();
  }
}
