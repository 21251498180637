import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ErrorMessagesService } from 'src/app/service/common';
import { getCheckboxItemName } from '../../utils/helper';

@Component({
  selector: 'cc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() hint = '';
  @Input() listCheckbox = [];
  @Input() selectAllBtn = true;
  @Input() pointMark = false;
  @Input() disabled = false;
  @Input() isSubmitted = false;
  @Input() control: FormControl;
  @Input() controlArray: FormArray;
  @Input() value: string[] = [];
  @Output() valueChange = new EventEmitter<string[]>();

  constructor(protected fb: FormBuilder, private errorMessages: ErrorMessagesService) {}

  isChecked(optionValue: string): boolean {
    if (this.controlArray) {
      return this.controlArray.value.some((v: string) => v === optionValue);
    }
    return this.value.some((v: string) => v === optionValue);
  }

  selectAll(): void {
    this.value = this.listCheckbox.map((item) => (item.id ? item.id : item.name));
    if (this.controlArray) {
      this.controlArray.clear();
      this.value.forEach((name) => this.controlArray.push(this.fb.control(name)));
    }
    this.valueChange.emit(this.value);
  }

  handleChange(event) {
    let otherValues: string[] = [];
    let result: string[] = [];

    if (this.controlArray) {
      otherValues = this.controlArray.value.filter((v: string) => v !== event.source.value);
    } else {
      otherValues = this.value.filter((v) => v !== event.source.value);
    }

    if (event.source.checked) {
      result = [...otherValues, event.source.value];
    } else {
      result = [...otherValues];
    }

    if (this.controlArray) {
      this.controlArray.clear();
      result.forEach((role) => this.controlArray.push(this.fb.control(role)));
    }
    this.valueChange.emit(result);
  }

  shouldShowErrors(): boolean {
    return this.errorMessages.shouldShowErrors(this.control, this.isSubmitted);
  }

  listOfErrors(): string[] {
    return this.errorMessages.listOfErrors(this.control, this);
  }

  getItemName(name: string) {
    return getCheckboxItemName(name);
  }
}
