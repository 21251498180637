<div class="scrollspy-menu">
  <nav id="myScrollspy">
    <ul class="nav nav-pills flex-column">
      <li class="nav-item" *ngFor='let item of menu; let i = index'>
        <a class="nav-link" routerLink="./" fragment="{{item.fragment}}" [ngClass]="{'select': section===item.fragment}">
          {{item.name}}
        </a>
        <ul *ngIf="showChild[i] && item && item.child && item.child.length > 0" class="nav nav-pills flex-column">
          <li class="nav-item" *ngFor='let childItem of item.child'>
            <a class="nav-link ml-3 my-1" routerLink="./" fragment="{{childItem.fragment}}" [ngClass]="{'select': section==childItem.fragment}">
              {{item.name}}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>
