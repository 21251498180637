import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-step-bar',
  templateUrl: './step-bar.component.html',
  styleUrls: ['./step-bar.component.scss']
})
export class StepBarComponent {
  @Input() stepList = [];
  @Input() currentStep: number;

}
