<div class="user-wrapper">
  <a *ngIf="!(userInfo$ | async)" class="login-btn" (click)="handleLogin()">
    <sdc-design-button
    type="primary"
    outline="true"
    title="Sign in"
    width="92px"
    height="36px"
  ></sdc-design-button>
  </a>

  <a *ngIf="userInfo$ | async" (click)="handleUserModal()">
    <img class="avatar" src="/assets/images/avatar.png" alt="avatar">
  </a>
</div>

  <ng-template #userModal>
    <ng-container *ngIf="userInfo$ | async as userInfo">
      <div class="user">
        <img class="inner-avatar" src="/assets/images/avatar.png" alt="avatar">
        <div class="user-info">
          <p class="username">{{userInfo?.user_name}}</p>
          <p class="login-id">{{userInfo?.login_id}}</p>
        </div>
      </div>
      <a rel="noopener" *ngIf="userInfo" class="edit-profile" href="{{profileLink}}" target="_blank" (click)="closeModal()">Edit profile</a>
      <a rel="noopener" *ngIf="userInfo" class="settings" routerLink="/user/settings" (click)="closeModal()">Portal Settings</a>
      <a rel="noopener" *ngIf="userInfo" class="logout-btn" (click)="handleLogout()">
        <sdc-design-button
        type="primary"
        title="Sign out"
        width="140px"
        height="40px"
      ></sdc-design-button>
      </a>
    </ng-container>
  </ng-template>