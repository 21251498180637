<span
  *ngIf="iconUrl"
  class="common-icon"
  [id]="id"
  [title]="iconTitle"
  [ngClass]="{ disabled: disabled }"
  [ngStyle]="{
    background: 'url(' + iconUrl + ')',
    width: width,
    height: height,
    'background-size': 'contain',
    'background-repeat': 'no-repeat'
  }"
  (click)="handleClick()"
  #tooltip="matTooltip"
  [matTooltipPosition]="'below'"
  [matTooltipDisabled]="!hasTooltip"
  [matTooltipPosition]="tooltipPosition"
  [matTooltip]="tooltipLabel"
>
</span>
