<div class="common-table-wrapper">
  <table class="table" aria-describedby="table">
    <thead class="thead-dark">
      <tr>
        <th scope="col" *ngIf="useIndex" class="header-cell">No</th>
        <th scope="col" *ngFor="let col of columnDef" class="header-cell">{{col?.headerName}}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="!bodyTemplate">
        <tr *ngFor="let item of items; let i = index;">
          <td *ngIf="useIndex" class="table-cell">{{ i + 1 }}</td>
          <td *ngFor="let col of columnDef" class="table-cell">{{item[col?.field]}}</td>
        </tr>
      </ng-container>
      <ng-container *ngIf="bodyTemplate">
        <ng-template ngFor [ngForOf]="items" [ngForTemplate]="bodyTemplate">
        </ng-template>
      </ng-container>
    </tbody>
  </table>
  <div *ngIf="items?.length === 0" class="no-result">
    <span>No Result</span>
  </div>
</div>
