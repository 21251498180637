import { Injectable, Inject, SecurityContext } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfo } from '../../common/interfaces/user.interface';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { WindowService } from './window.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class AuthService {
  userSubject = new BehaviorSubject<UserInfo>(null);
  userInfo$ = this.userSubject.asObservable();
  private _window: WindowService;

  constructor(
    private http: HttpClient,
    private windowService: WindowService,
    @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer
  ) {
    this._window = this.windowService;
  }

  getUserInfo(): Observable<UserInfo> {
    return this.http
      .get<UserInfo>(`${environment.api}/v1/auth/user`)
      .pipe(tap((userInfo: UserInfo) => this.userSubject.next(userInfo)));
  }

  checkLogin(): Observable<any> {
    return this.http.get<any>(`${environment.api}/v1/auth/check`);
  }

  deleteAccount(): Observable<void> {
    return this.http.delete<void>(`${environment.api}/v1/auth/user`);
  }

  isAuthenticated(): Observable<boolean> {
    return this.getUserInfo().pipe(map((userInfo: UserInfo) => !!userInfo));
  }

  isUnlimitedUser(): Observable<boolean> {
    return this.userInfo$.pipe(map((userInfo: UserInfo) => userInfo && userInfo.bypass_project_quota));
  }

  isOwnerOfProject(loginId: string): Observable<boolean> {
    return this.userInfo$.pipe(map((userInfo: UserInfo) => userInfo && userInfo.login_id === loginId));
  }

  isSecondPartyUser(): Observable<boolean> {
    return this.userInfo$.pipe(
      map((userInfo: UserInfo) => userInfo && userInfo.user_roles.some((role) => role === 'second_party_user'))
    );
  }

  login(): void {
    const href = this.getSafeUrl(this._window.nativeWindow.location.href);
    this._window.open(`${environment.api}/oauth2/authorization/samsung?redirect_uri=${href}`, '_self');
  }

  logout(): void {
    this._window.open(`${environment.api}/logout`, '_self');
  }

  logoutWhenDelete(): void {
    const protocol = this._window.nativeWindow.location.protocol;
    const host = this._window.nativeWindow.location.host;
    const url = protocol + '//' + host;
    const safeUrl = this.getSafeUrl(url);
    this._window.open(`${environment.api}/logout?redirect_uri=${safeUrl}/account-deleted`, '_self');
  }

  getSafeUrl(url: string): string {
    return this.sanitizer.sanitize(SecurityContext.URL, url);
  }

  getEnvironmentFields(): Observable<void> {
    return this.http.get<void>(`${environment.api}/v1/common/features`);
  }
}
