import { AbstractControl, ValidatorFn } from '@angular/forms';

export function DifferentConsentCountryValidator(list: any, field?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const consentCountries: string[] = list.map((consent: any) => {
      return consent[0];
    });

    if (control.value && consentCountries.includes(control.value.toLowerCase())) {
      if (!field || (field && control.value.toLowerCase() !== field.toLowerCase())) {
        return { differentCountry: 'Country Already Registered! Please use a different country!' };
      }
    }
    return null;
  };
}
