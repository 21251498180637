import { Injectable } from '@angular/core';

function getWindow(): any {
  return window;
}

@Injectable({ providedIn: 'root' })
export class WindowService {
  get nativeWindow(): any {
    return getWindow();
  }

  open(url: string, target: string): void {
    const newWindow = this.nativeWindow.open(url, target);
    newWindow.opener = null;
  }
}
