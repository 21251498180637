import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IpService {
  constructor(private http: HttpClient) {}

  checkAccess(): Observable<boolean> {
    return this.http
      .get<any>(`${environment.api}/v1/ip/check`, { observe: 'response' })
      .pipe(map((res) => res.status === 200));
  }
}
