import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-warning',
  templateUrl: 'warning.component.html',
  styleUrls: ['warning.component.scss']
})
export class WarningComponent implements OnDestroy {

  public warningHead = "";
  public warningMessage = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { 
    this.warningHead = this.dialogData.header;
    this.warningMessage = this.dialogData.message;
  }
  
  ngOnDestroy() {	
    this.warningHead = null;
    this.warningMessage = null;
  }

}
