import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { ErrorMessagesService } from '../../../service/common';
import { CustomRedirectUrlValidator, EmailValidator } from '../../form-validator';

@Component({
  selector: 'cc-webhook-multiple-input',
  templateUrl: './webhook-multiple-input.component.html',
  styleUrls: ['./webhook-multiple-input.component.scss'],
})
export class WebhookMultipleInputComponent implements OnChanges {
  @Input() controlArray: FormArray;
  @Input() initialValue: string[] = [];
  @Input() maxSize = 100;
  @Input() email: boolean = false;
  @Input() title = '';
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() isSubmitted = false;
  @Input() errorMessage = '';
  @Input() list = [];
  @Input() hide = false;
  @Input() button = true;
  @Output() listChange: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected fb: FormBuilder, private errorMessages: ErrorMessagesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue?.currentValue) {
      this.controlArray.clear();
      changes.initialValue.currentValue.forEach((url: string) => this.addRow(url));
    } else {
      this.controlArray.clear();
      this.addRow();
    }
    if (!this.formArrayControls.length) {
      this.controlArray.clear();
      this.addRow();
    }
  }

  get formArrayControls(): FormControl[] {
    return this.controlArray.controls as FormControl[];
  }

  handleChange(): void {
    this.errorMessages.checkDuplicity(this.formArrayControls);
    this.listChange.emit(this.controlArray.value);
  }

  addRow(url = '') {
    this.controlArray.push(
      this.fb.control(url, [this.email === true ? EmailValidator('samsung.com', true) : CustomRedirectUrlValidator()])
    );
  }

  removeRow(index: number) {
    this.controlArray.removeAt(index);
    this.listChange.emit(this.controlArray.value);
  }

  handleClick(event: any, item: number) {
    event.preventDefault();
    this.onClick.emit(item);
  }
}
