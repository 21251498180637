<div class="output-text">
  <div class="title">{{title}}</div>
  <div class="output-box">
    <span class="output" *ngIf="typeOutput === 'value'">{{content}}</span>
    <div *ngIf="typeOutput === 'object'">
      <div class="detail" *ngFor="let item of content">
        <span class="left-detail output">{{item.title}}:</span>
        <span class="right-detail output">{{item.value}}</span>
      </div>
    </div>
    <div *ngIf="typeOutput === 'array'">
      <div class="detail" *ngFor="let item of content">
        <span class="output">{{item.selectValue}}: {{item.inputValue}}</span>
      </div>
    </div>
  </div>
</div>