import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NameValidator(param: string = ''): ValidatorFn {
  const app_alias_regex = /^[A-Za-z0-9-]+$/;
  const validator_regex = /^([-\s\d._()]|\p{L}\p{M}*)+$/iu;
  const regex = param === 'app_alias' ? app_alias_regex : validator_regex; // support korean and chinese

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && !regex.test(control.value)) {
      return param === 'app_alias' ? { alias: true } : { name: true };
    }
    return null;
  };
}
