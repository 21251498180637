<div class="localized-input">
  <div class="input-list">
    <div class="list">
      <div class="input-contain" *ngFor="let item of formArrayGroups; let i = index">
        <span *ngIf="!hasDefault || (hasDefault && i !== 0)" class="name-icon">
          <cc-dropdown 
            [title]="title"
            defaultLabel="Choose one" 
            [control]="formGroupControls(i, 'selectValue')" 
            [dropdownMenu]="dropLocaleMenu"
            [isSubmitted]="isSubmitted"
            (valueChange)="checkDropdownValues($event, i)"
            [disabled]="selectedList"
            [disableSelectedItems]="true"
            [selectedValues]="localizedNamesSelected"
            [projectDropdown]="true"
          >
          </cc-dropdown>
        </span>
        <span *ngIf="hasDefault && i === 0" class="default-title">Default:</span>
        <div class="ii-contain">
          <div class="input-text">
            <cc-input-text 
              [title]="title"
              [control]="formGroupControls(i, 'inputValue')" 
              [placeholder]="placeholder"
              [isSubmitted]="isSubmitted" 
              [isRequired]="isRequired" 
              [hint]="hint"
            >
            </cc-input-text>
            <span class="icon-contain" *ngIf="formArrayGroups.length > 1">
              <cc-icon class="localized-remove" [iconUrl]="'/assets/images/icons/delete-icon-ux.png'" (clickIcon)="removeRow(i)"></cc-icon>
            </span>
          </div>
        </div> 
      </div>
      <span *ngIf="dropLocaleMenu.length !== formArrayGroups.length " class="add-icon-contain">
        <cc-icon class="localized-add" [iconUrl]="'/assets/images/icons/add-icon.png'" (clickIcon)="addRow({})"></cc-icon>
      </span>
    </div>
  </div>
</div>
