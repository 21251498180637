import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() title = '';
  @Input() existIcon = false;
  @Input() iconUrl = '';
  @Input() type = 'blue';
  @Input() id = '';
  @Input() disabled = false;
  @Output() onClick: EventEmitter<void> = new EventEmitter();

  handleClick(event) {
    event.preventDefault();
    this.onClick.emit();
  }

}
