import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Project } from 'src/app/common/interfaces/project.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  public searchValueSource = new BehaviorSubject<boolean>(true);
  searchValue = this.searchValueSource.asObservable();

  constructor(private http: HttpClient) {}

  getProjectList(): Observable<Project[]> {
    return this.http.get<Project[]>(`${environment.api}/v1/projects`);
  }

  getProject(id: string): Observable<Project> {
    return this.http.get<Project>(`${environment.api}/v1/projects/${id}`);
  }

  createProject(project: Project): Observable<Project> {
    return this.http.post<Project>(`${environment.api}/v1/projects`, project);
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.put<Project>(`${environment.api}/v1/projects/${project.project_id}`, project);
  }

  deleteProjectByID(projectId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api}/v1/projects/${projectId}`);
  }

  setSearchValue(value: boolean): void {
    this.searchValueSource.next(value);
  }
}
