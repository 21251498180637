import { ActivatedRoute } from '@angular/router';
import { OnInit, Component } from '@angular/core';

@Component({
  templateUrl: 'callback.component.html'
})
export class CallbackComponent implements OnInit {

  constructor(protected route: ActivatedRoute) {}

  ngOnInit() {
    console.info(this.route.snapshot.queryParams);
  }

}
