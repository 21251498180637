<div class="multiple-input" *ngIf="!hide">
  <div class="input-list">
    <div class="input-contain" *ngFor="let item of formArrayControls; let i = index">
      <div class="ii-contain" [ngClass]="clientMigration && item.valid === false ? 'input-text__warning' : ''">
        <div
          class="input-text"
          [ngClass]="getDisplayRemove() && getDisplayAdd(i) ? 'input-text_double__control' : 'input-text__control'"
        >
          <cc-input-text
            [title]="title"
            [hideTitle]="i !== 0"
            [control]="formArrayControls[i]"
            [readonly]="isReadOnly"
            [placeholder]="placeholder"
            [isSubmitted]="isSubmitted"
            [ngClass]="{ 'input-text__warning': clientMigration && item.valid === false }"
            [disabled]="disabled"
            [hint]="hint"
            [value]="formArrayControls[i].value"
            (focusout)="focusout($event)"
            (valueChange)="handleChange()"
            [errorMessage]="
              clientMigration && item.valid === false ? 'Please fix this URL after you finish the migration!' : ''
            "
          ></cc-input-text>
        </div>
        <span *ngIf="getDisplayRemove()" class="icon-contain" [class.first]="i === 0">
          <cc-icon [iconUrl]="'/assets/images/icons/delete-icon-ux.png'" (clickIcon)="removeRow(i)"></cc-icon>
        </span>
      </div>
      <span *ngIf="getDisplayAdd(i)" class="add-icon-contain" [class.first]="i === 0">
        <cc-icon
          [iconUrl]="'/assets/images/icons/add-icon.png'"
          (clickIcon)="!limitReached ? addRow() : toggleLimitExceedError(true)"
        ></cc-icon>
      </span>
    </div>
  </div>
  <div *ngIf="getLimitExceedError()">
    <span class="errorMessage">{{ limitErrorMessage }}</span>
  </div>
  <div class="add-input" *ngIf="!formArrayControls.length">
    <cc-icon [iconUrl]="'/assets/images/icons/add-icon.png'" (clickIcon)="addRow()"></cc-icon>
  </div>
</div>
