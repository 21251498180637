import { createReducer } from '@ngrx/store';
import { ENVIRONMENT_TYPE } from '../actions/environment.action';

export const initialState = {
  webhook: false,
  apiDashboard: false,
  businessDepartment: false,
  tagid: false,
  graphql: false,
  saml: false,
};

const _environmentReducer = createReducer(initialState);

export const environmentReducer = (state, action) => {
  const payload = action.payload;

  if (action.type === ENVIRONMENT_TYPE) {
    return {
      ...state,
      webhook: payload.webhook,
      apiDashboard: payload.apiDashboard,
      businessDepartment: payload.businessDepartment,
      tagid: payload.tagid,
      graphql: payload.graphql,
      temporaryid: payload.temporaryid,
      oidcCreate: payload.oidcCreate,
      saml: payload.saml,
    };
  } else {
    return _environmentReducer(state, action);
  }
};
