import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './component/table/table.component';
import { RadioComponent } from './component/radio/radio.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { InputTextComponent } from './component/input-text/input-text.component';
import { ButtonComponent } from './component/button/button.component';
import { OutputTextComponent } from './component/output-text/output-text.component';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { DropdownMultipleComponent } from './component/dropdown-multiple/dropdown-multiple.component';
import { ConfirmModalComponent } from './component/confirm-modal/confirm-modal.component';
import { CheckboxComponent } from './component/checkbox/checkbox.component';
import { IconComponent } from './component/icon/icon.component';
import { LocalizedInputComponent } from './component/localized-input/localized-input.component';
import { SwitchComponent } from './component/switch/switch.component';
import { InputSearchComponent } from './component/input-search/input-search.component';
import { StepBarComponent } from './component/step-bar/step-bar.component';
import { ScrollspyMenuComponent } from './component/scrollspy-menu/scrollspy-menu.component';
import { ScrollSpyDirective } from './component/scrollspy-menu/scrollspy.directive';
import { MultipleInputComponent } from './component/multiple-input/multiple-input.component';
import { LoadingWidgetComponent } from './component/loading-widget/loading-widget.component';
import { DragDropDirective } from './component/drag-drop-file/drag-drop.directive';
import { DragDropFileComponent } from './component/drag-drop-file/drag-drop-file.component';
import { ProtocolTypePipe } from './pipe/protocol-type.pipe';
import { ScopePipe } from './pipe/scope.pipe';
import { ServiceTypePipe } from './pipe/service-type.pipe';
import { PackageInputComponent } from './component/package-input/package-input.component';
import { WebhookMultipleInputComponent } from './component/webhook-multiple-input/webhook-multiple-input.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LastUsageComponent } from './component/last-usage/last-usage.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DesignSystemModule } from '@sdc-design-system/design-system';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CheckboxSingleComponent } from './component/checkbox/checkbox-single/checkbox-single.component';
import { CheckboxSingleMultilineComponent } from './component/checkbox/checkbox-single-multiline/checkbox-single-multiline.component';

@NgModule({
  imports: [
    AngularCommonModule,
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTooltipModule,
    DesignSystemModule,
    MatSlideToggleModule,
  ],
  declarations: [
    // Component
    NavbarComponent,
    InputTextComponent,
    ButtonComponent,
    OutputTextComponent,
    DropdownComponent,
    DropdownMultipleComponent,
    OutputTextComponent,
    ConfirmModalComponent,
    CheckboxComponent,
    CheckboxSingleComponent,
    CheckboxSingleMultilineComponent,
    IconComponent,
    LocalizedInputComponent,
    PackageInputComponent,
    RadioComponent,
    SwitchComponent,
    TableComponent,
    InputSearchComponent,
    StepBarComponent,
    ScrollspyMenuComponent,
    ScrollSpyDirective,
    MultipleInputComponent,
    WebhookMultipleInputComponent,
    LoadingWidgetComponent,
    DragDropFileComponent,
    LastUsageComponent,

    // Directive
    DragDropDirective,

    // Pipe
    ProtocolTypePipe,
    ServiceTypePipe,
    ScopePipe,
  ],
  exports: [
    MatDialogModule,
    NavbarComponent,
    AngularCommonModule,
    FormsModule,
    InputTextComponent,
    DesignSystemModule,
    ButtonComponent,
    OutputTextComponent,
    DropdownComponent,
    DropdownMultipleComponent,
    OutputTextComponent,
    ConfirmModalComponent,
    CheckboxComponent,
    CheckboxSingleComponent,
    CheckboxSingleMultilineComponent,
    IconComponent,
    LocalizedInputComponent,
    PackageInputComponent,
    RadioComponent,
    SwitchComponent,
    TableComponent,
    InputSearchComponent,
    StepBarComponent,
    ScrollspyMenuComponent,
    ScrollSpyDirective,
    MultipleInputComponent,
    WebhookMultipleInputComponent,
    LoadingWidgetComponent,
    DragDropFileComponent,
    LastUsageComponent,
    MatSlideToggleModule,

    // Directive
    DragDropDirective,

    // Pipe
    ProtocolTypePipe,
    ServiceTypePipe,
    ScopePipe,
  ],
  providers: [ProtocolTypePipe, ServiceTypePipe, ScopePipe],
})
export class CommonModule {}
