import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorMessagesService } from '../../../service/common';

@Component({
  selector: 'cc-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent {
  constructor(private errorMessages: ErrorMessagesService) {}

  @Input() title = '';
  @Input() name = '';
  @Input() hint = '';
  @Input() showHintOn = '';
  @Input() listRadio = [];
  @Input() disabled = false;
  @Input() isRequired = false;
  @Input() isSubmitted = false;
  @Input() control: FormControl;
  @Input() list: boolean = true;
  @Input() value = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  handleChange(event: any) {
    const value = event.target.value;
    if (this.control) {
      this.control.setValue(value);
    }
    this.valueChange.emit(value);
  }

  shouldShowErrors(): boolean {
    return this.errorMessages.shouldShowErrors(this.control, this.isSubmitted);
  }

  listOfErrors(): string[] {
    return this.errorMessages.listOfErrors(this.control, this);
  }
}
