<div class="header-wrapper">
  <div class="subheader">
    <div class="header-logo" routerLink="/">
      <img class="logo" src="assets/images/logo.png" alt="Samsung Account">
      <div class="sub-logo">Developer Console</div>
    </div>
    <div class="user" *ngIf="shouldDisplayUserIcon">
      <app-user></app-user>
    </div>
  </div>
</div>
