<div class="loading small ">
    <div class="loading-widget-container" [ngStyle]="{'width': width + 'px', 'height': width/2 + 'px'}">
        <div class="top">
            <div class="circle"></div>
        </div>
    </div>
    <div class="loading-widget-container" [ngStyle]="{'width': width + 'px', 'height': width/2 + 'px'}">
        <div class="bottom">
            <div class="circle" [ngStyle]="{'top': '-' + width/2 + 'px'}"></div>
        </div>
    </div>
</div>
