<div class="navbar">
  <ul class="ul-level1">
    <li *ngFor="let item of listMenu; let i = index;" class="li-level1">
      <div *ngIf="(!(userInfo$ | async) && item.name !=='Projects') || (userInfo$ | async)" class="item-wrapper">
        <a href="javascript:" class="level1" [routerLink]="item.routerLink"
          [class.active]="routerUrl.includes(item.routerLink)">
          {{item.name}}
        </a>
      </div>
    </li>
  </ul>
</div>