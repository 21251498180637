<div class="confirm-modal">
  <a class="close" [mat-dialog-close]>
    <img class="close-icon" src="/assets/images/icons/close-icon.png" alt="close-icon" />
  </a>
  <div>
    <div class="title">{{ title }}</div>
    <div class="content" [formGroup]="form">
      <p *ngIf="content">{{ content }}</p>
      <p *ngIf="useConfirmWord">Please input "{{ confirmWord }}" to confirm you are certain about this action.</p>
      <cc-input-text
        *ngIf="useConfirmWord"
        placeholder="Enter &quot;{{ confirmWord }}&quot; in this space"
        [control]="form.controls.confirmPass"
        [(value)]="confirmPass"
      ></cc-input-text>
    </div>
    <div class="proceed-button">
      <sdc-design-button
       *ngIf="showBackButton"
        class="backbutton"
        [title]="'Back'"
        type="primary"
        outline="true"
        width="150px"
        height="40px"
        (click)="handleClickBackButton()"
      ></sdc-design-button>

      <sdc-design-button
        [outline]="handleButtonOutline()"
        [title]="confirmButtonTitle"
        [type]="handleButtonType()"
        (click)="handleClickButton()"
        width="150px"
        height="40px"
      ></sdc-design-button>
    </div>
  </div>
</div>
