import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusTest } from 'src/app/common/interfaces/common.interface';
import { WebhookInfo } from 'src/app/common/interfaces/webhook.interface';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class WebhookService {
  constructor(private http: HttpClient) {}

  getWebhookFormData(webhook) {
    const {
      user_withdrawal_notice_url,
      user_modify_login_id_notice_url,
      user_modify_notice_url,
      user_valid_email_notice_url,
      user_create_notice_url,
      user_name_check_notice_url,
      user_tnc_notice_url,
      user_expire_token_notice_url,
      user_unused_notice_url,
      user_unlock_notice_url,
      user_notify_newsletter_receive_notice_url,
      user_phone_id_suspended_notice_url,
      device_withdrawal_notice_url,
      device_modify_notice_url,
      device_create_notice_url,
      linking_register_notice_url,
      linking_withdrawal_notice_url,
      developer_login_id,
      country_code,
      content_type,
    } = webhook;

    return {
      user_withdrawal_notice_url,
      user_modify_login_id_notice_url,
      user_modify_notice_url,
      user_valid_email_notice_url,
      user_create_notice_url,
      user_name_check_notice_url,
      user_tnc_notice_url,
      user_expire_token_notice_url,
      user_unused_notice_url,
      user_unlock_notice_url,
      user_notify_newsletter_receive_notice_url,
      user_phone_id_suspended_notice_url,
      device_withdrawal_notice_url,
      device_modify_notice_url,
      device_create_notice_url,
      linking_register_notice_url,
      linking_withdrawal_notice_url,
      developer_login_id,
      country_code,
      content_type,
    };
  }

  getWebhooksStatus(clientId: string): Observable<any> {
    return this.http.get<any>(`${environment.api}/v1/webhook/${clientId}`);
  }

  updateWebhookStatus(webhookInfo: WebhookInfo, clientId: string, region: string, status: string): Observable<void> {
    return this.http.put<void>(`${environment.api}/v1/webhook/${region}/status/${clientId}/${status}`, webhookInfo);
  }

  createWebhookInfo(webhookInfo: WebhookInfo, region: string, clientId: string): Observable<void> {
    return this.http.post<void>(`${environment.api}/v1/webhook/${region}/${clientId}`, webhookInfo);
  }

  updateWebhookInfo(webhookInfo: WebhookInfo, clientId: string, region: string): Observable<void> {
    return this.http.put<void>(`${environment.api}/v1/webhook/${region}/${clientId}`, webhookInfo);
  }

  getEndpointEvent(region: string, clientId: string, event: string, endpoint: string): Observable<StatusTest> {
    const params = { eventType: event, endpoint: endpoint };
    return this.http.get<StatusTest>(`${environment.api}/v1/webhook/${region}/${clientId}/test`, { params });
  }
}
