import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'cc-drag-drop-file',
  templateUrl: './drag-drop-file.component.html',
  styleUrls: ['./drag-drop-file.component.scss'],
})
export class DragDropFileComponent implements OnChanges {
  public file: File = null;
  public filename = '';
  public fileSize = 0;
  public fileLoaded = false;
  public preUpload = true;
  public imageSrc: any;
  public fileChanged = false;
  public errorMsg = '';

  public control = new FormControl(null);
  private allowImagemFormats = ['jpg', 'jpeg', 'png', 'bm'];
  private allowFileFormats = ['pdf'];

  @Input() type: string = 'image';
  @Input() title = '';
  @Input() placeholder = '';
  @Input() value: any = '';
  @Input() fileToDownload: string = '';
  @Input() src = '';
  @Input() hint = '';
  @Input() maxSize = null; // unit: MB
  @Input() isSubmitted = false;
  @Input() removeId: string = '';

  @Output() valueChange: EventEmitter<File> = new EventEmitter();
  @Output() onFileRemove: EventEmitter<void> = new EventEmitter();
  @Output() onFileDownload: EventEmitter<void> = new EventEmitter();

  ngOnChanges() {
    if (this.value && this.value instanceof File) {
      this.file = this.value;
      this.loadImage(this.file);
    }

    if (this.fileToDownload) {
      this.filename = this.fileToDownload;
    }
  }

  loadImage(image: File) {
    this.file = image;
    this.fileChanged = true;
    this.preUpload = false;
    this.fileLoaded = false;
    const fileType = this.file.name.split('.').pop();
    this.filename = this.file.name.replace('.' + fileType, '');
    this.filename = this.filename.substring(0, 5) + '...' + fileType;
    this.fileSize = Math.round((this.file.size / 1024) * 100) / 100;

    let isImage: boolean = this.type === 'image';

    setTimeout(() => {
      this.move();
    }, 0);
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = (e) => {
      this.imageSrc = isImage ? reader.result : null;
    };

    let allowFormats = isImage ? this.allowImagemFormats : this.allowFileFormats;

    if (
      (this.maxSize && this.file.size / 1024 / 1024 > this.maxSize) ||
      allowFormats.indexOf(fileType.toLowerCase()) < 0
    ) {
      this.control.setErrors({ invalid: true });
      this.errorMsg = `Maximum file size is ${this.maxSize} MB. Allowed ${
        isImage ? 'formats are JPEG, PNG and BM.' : 'format is PDF.'
      }`;
    }

    this.value = this.file.name;
  }

  changeFile(event) {
    let files: any;
    const isDropped: boolean = !event.target;
    if (isDropped) {
      files = event;
    } else {
      files = event.target.files;
      if (files.length === 0) {
        return;
      }
    }
    this.control.setErrors(null);
    this.control.markAsTouched();
    this.loadImage(files[0]);
    if (!this.control.valid) {
      this.file = null;
    }
    this.valueChange.emit(this.file);
  }

  public shouldShowErrors(): boolean {
    return this.control && this.control.invalid;
  }

  public removeFile() {
    this.fileChanged = true;
    this.file = null;
    this.preUpload = true;
    this.fileLoaded = false;
    this.control.setErrors(null);
    this.onFileRemove.emit();
  }

  private triggerFileLoaded() {
    this.fileLoaded = true;
  }

  private move() {
    var elem = document.getElementById('markbar');
    var width = 1;
    if (elem) {
      var id = setInterval(() => {
        if (width >= 100) {
          this.triggerFileLoaded();
          clearInterval(id);
        } else {
          width++;
          elem.style.width = width + '%';
        }
      }, 5);
    }
  }

  downloadFile() {
    this.onFileDownload.emit();
  }
}
