<div class="footer">
  <div class="left-footer">
    <img class="footer-logo" src="assets/images/footer-logo.png" alt="Samsung Account">
    <div class="copyright">Copyright© Samsung Electronics Co., Ltd. All rights reserved.</div>
  </div>
  <div class="quick-link">
    <div class="navbar">
      <ul>
        <li>
          <a href="https://account.samsung.com/membership/terms/privacypolicy">Privacy Policy</a>
        </li>
      </ul>
    </div>
  </div>
</div>
