import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalizedNameInfo } from '../../interfaces/common.interface';

@Component({
  selector: 'cc-localized-input',
  templateUrl: './localized-input.component.html',
  styleUrls: ['./localized-input.component.scss'],
})
export class LocalizedInputComponent implements OnChanges {
  @Input() title = '';
  @Input() list = [];
  @Input() hasDefault = true;
  @Input() placeholder = '';
  @Input() hint = '';
  @Input() controlArray: FormArray;
  @Input() maxSize = null;
  @Input() clickable = false;
  @Input() isSubmitted = false;
  @Input() isRequired = false;
  @Input() dropLocaleMenu = [];
  @Input() initialValue: LocalizedNameInfo[] = [];

  @Output() localizedInputChange: EventEmitter<any> = new EventEmitter();

  localizedNamesSelected = [];

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialValue?.currentValue) {
      this.controlArray.clear();
      const names: LocalizedNameInfo[] = changes.initialValue.currentValue;
      names.forEach((name: LocalizedNameInfo) => this.addRow(name));
      if (this.localizedNamesSelected.length === 0) {
        this.initialValue.forEach((value, index) => {
          this.localizedNamesSelected[index] = { index: index, event: value.selectValue };
        });
      }
    }
    if (this.controlArray.length === 0) {
      this.addRow();
    }
  }

  get formArrayGroups(): FormGroup[] {
    return this.controlArray.controls as FormGroup[];
  }

  formGroupControls(index: number, control: string): FormControl {
    return this.formArrayGroups[index].controls[control] as FormControl;
  }

  addRow(names = {} as LocalizedNameInfo) {
    let validators = [Validators.maxLength(256)];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    this.controlArray.push(
      this.fb.group({
        inputValue: this.fb.control(names.inputValue ? names.inputValue : '', validators),
        selectValue: this.fb.control(names.selectValue ? names.selectValue : '', validators),
      })
    );
  }

  removeRow(index: number): void {
    this.controlArray.removeAt(index);
    this.localizedNamesSelected.splice(index, 1);
  }

  get controlArrayErrors(): any {
    return this.controlArray.errors;
  }

  checkDropdownValues(e: Event, i: number) {
    this.localizedNamesSelected[i] = { index: i, event: e };
    this.localizedInputChange.emit();
  }
}
