<div class="input-text-contain" [class.error]="shouldShowErrors()" [class.focus]="focus" [class.disabled]="disabled">
  <div *ngIf="title && !hideTitle" class="title">{{ title }}</div>
  <div class="input-text">
    <div class="input-wrapper {{ handleInputIconClass() }}" [ngClass]="{ disabled: disabled }">
      <input
        [type]="type"
        [placeholder]="placeholder"
        [value]="control ? control.value : value"
        [readonly]="readonly"
        [attr.disabled]="disabled ? '' : null"
        (input)="handleChange($event)"
        (focus)="handleFocus()"
        (blur)="handleBlur($event)"
        [min]="min"
        [id]="id"
        [attr.maxLength]="maxLength ? maxLength : null"
        [ngClass]="placeholderColor ? 'custom-' + placeholderColor + '__placeholder' : ''"
        (keydown.enter)="handleKeyEnter()"
      />
      <mat-icon *ngIf="inputMatIcon">{{ inputMatIcon }}</mat-icon>
    </div>
    <div *ngIf="errorMessage">
      <div class="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
    <div *ngIf="!errorMessage && shouldShowErrors()">
      <div class="errorMessage" *ngFor="let error of listOfErrors()">
        {{ error }}
      </div>
    </div>
  </div>

  <div *ngIf="showHint()" class="hint">
    <cc-icon width="16px" height="16px" [iconUrl]="'/assets/images/icons/hint-icon.png'"></cc-icon>
    <span>{{ hint }}</span>
  </div>
</div>
